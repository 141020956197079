import {
  Card,
  FormGroup,
  Menu,
  MenuItem,
  Popover,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider, { Settings } from "react-slick";
import useWindowSize, { WindowSize } from "../helpers/hooks/useWindowSize";
import CustomArrow from "./CustomArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import survey, { updateSelectedLanguage } from "../store/slices/survey";
import { useIntl } from "react-intl";
import { SessionStorageKeys } from "../constants/session-storage";
import { TrackAction } from "../helpers/tracker";

export enum Locale {
  English = "en",
  Spanish = "es",
  BrazilianPortuguese = "pt",
  HaitianCreole = "ht",
  Arabic = "ar",
  Afrikaans = "af",
  Albanian = "sq",
  Amharic = "am",
  Armenian = "hy",
  Bengali = "bn",
  Dari = "prs",
  Estonian = "et",
  Georgian = "ka",
  German = "de",
  Greek = "el",
  Gujarati = "gu",
  Hebrew = "he",
  Hindi = "hi",
  Japanese = "ja",
  Kiche = "quc",
  Korean = "ko",
  Malayalam = "ml",
  Polish = "pl",
  Russian = "ru",
  SimplifiedChinese = "zh",
  Tagalog = "tl",
  Tamil = "ta",
  Telugu = "te",
  TraditionalChinese = "zh-CH",
  Turkish = "tr",
  Ukrainian = "uk",
  Urdu = "ur",
  Vietnamese = "vi",
}

export const LocaleEnglishValues: any = {
  [Locale.English]: "English",
  [Locale.Spanish]: "Spanish",
  [Locale.BrazilianPortuguese]: "Brazilian Portuguese",
  [Locale.HaitianCreole]: "Haitian Creole",
  [Locale.Arabic]: "Arabic",
  [Locale.Afrikaans]: "Afrikaans",
  [Locale.Albanian]: "Albanian",
  [Locale.Amharic]: "Amharic",
  [Locale.Armenian]: "Armenian",
  [Locale.Bengali]: "Bengali",
  [Locale.Dari]: "Dari",
  [Locale.Estonian]: "Estonian",
  [Locale.Georgian]: "Georgian",
  [Locale.German]: "German",
  [Locale.Greek]: "Greek",
  [Locale.Gujarati]: "Gujarati",
  [Locale.Hebrew]: "Hebrew",
  [Locale.Hindi]: "Hindi",
  [Locale.Japanese]: "Japanese",
  [Locale.Kiche]: "K'iche",
  [Locale.Korean]: "Korean",
  [Locale.Malayalam]: "Malayalam",
  [Locale.Polish]: "Polish",
  [Locale.Russian]: "Russian",
  [Locale.SimplifiedChinese]: "Simplified Chinese",
  [Locale.Tagalog]: "Tagalog",
  [Locale.Tamil]: "Tamil",
  [Locale.Telugu]: "Telugu",
  [Locale.TraditionalChinese]: "Traditional Chinese",
  [Locale.Turkish]: "Turkish",
  [Locale.Ukrainian]: "Ukrainian",
  [Locale.Urdu]: "Urdu",
  [Locale.Vietnamese]: "Vietnamese",
};

export const LocaleDisplayedValues: any = {
  [Locale.English]: "English",
  [Locale.Spanish]: "Español",
  [Locale.BrazilianPortuguese]: "Portuguese",
  [Locale.HaitianCreole]: "Kreyòl Ayisyen",
  [Locale.Arabic]: "عربى",
  [Locale.Afrikaans]: "Afrikaans",
  [Locale.Albanian]: "Shqip",
  [Locale.Amharic]: "አማርኛ",
  [Locale.Armenian]: "Հայերեն",
  [Locale.Bengali]: "বাঙালি",
  [Locale.Dari]: "دری",
  [Locale.Estonian]: "Eesti Keel",
  [Locale.Georgian]: "ქართული",
  [Locale.German]: "Deutsch",
  [Locale.Greek]: "Ελληνικά",
  [Locale.Gujarati]: "ગુજરાતી",
  [Locale.Hebrew]: "עברית",
  [Locale.Hindi]: "हिन्दी",
  [Locale.Japanese]: "日本語",
  [Locale.Kiche]: "K'iche",
  [Locale.Korean]: "한국어",
  [Locale.Malayalam]: "മലയാളം",
  [Locale.Polish]: "Polski",
  [Locale.Russian]: "Русский",
  [Locale.SimplifiedChinese]: "简体中文",
  [Locale.Tagalog]: "Tagalog",
  [Locale.Tamil]: "தமிழ்",
  [Locale.Telugu]: "తెలుగు",
  [Locale.TraditionalChinese]: "繁體中文",
  [Locale.Turkish]: "Türkçe",
  [Locale.Ukrainian]: "Українська",
  [Locale.Urdu]: "اردو",
  [Locale.Vietnamese]: "tiếng Việt",
};

export const LanguageSelector = (props: { renderOnNavbar: boolean }) => {
  // props
  const { renderOnNavbar } = props;

  // useIntl
  const intl = useIntl();

  // helper hook for window size
  const windowSize = useWindowSize();

  // useDispatch
  const dispatch = useDispatch();

  // useSelector
  const available_languages = useSelector(
    (s: any) => s.survey.data.completedSurvey?.available_languages
  );
  // useSelector
  const error_data_available_languages = useSelector(
    (s: any) => s.survey.data?.errorData?.available_languages
  );
  const selectedLang = useSelector((s: any) => s.survey.ui.language);
  const showLanguageSelector = useSelector(
    (s: any) => s.survey.ui.showLanguageSelector
  );

  // useMemo selected Language
  const { selectedLanguage, isSurveyLanguageSelected } = useMemo(() => {
    let language = sessionStorage.getItem(SessionStorageKeys.Locale);
    if (!language)
      return {
        selectedLanguage: Locale.English,
        isSurveyLanguageSelected: false,
      };
    else return { selectedLanguage: language, isSurveyLanguageSelected: true };
  }, [selectedLang]);

  // tracking language selection
  useEffect(() => {
    (async () => {
      await TrackAction({
        action: "languageChange",
        other: JSON.stringify({
          selectedLanguage,
          isSurveyLanguageSelected,
        }),
      });
    })();
  }, [selectedLanguage, isSurveyLanguageSelected]);

  // function
  const handleLocaleChange = (locale: string) => {
    dispatch(updateSelectedLanguage(locale));
  };

  // number of slides to show in react-slick
  const slidesToShow = useMemo(() => {
    const itemsLength: number = available_languages
      ? available_languages?.length
      : error_data_available_languages?.length;
    switch (windowSize) {
      case WindowSize.SM:
        return 1;
      case WindowSize.MD:
        return itemsLength === 1 ? 1 : 2; //return menuCollapsed ? 1 : itemsLength === 1 ? 1 : 2; - removed
      default:
        return itemsLength < 5 ? itemsLength : 5;
    }
  }, [windowSize, available_languages, error_data_available_languages]); //menuCollapsed, - removed

  // configuration for react-slick
  const settings: Settings = useMemo(
    () => ({
      dots: slidesToShow == 1 ? false : true,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      prevArrow: <CustomArrow isPrev />,
      nextArrow: <CustomArrow />,
    }),
    [slidesToShow]
  );
  
  return !showLanguageSelector ? (
    <></>
  ) : renderOnNavbar ? (
    <Popover
      content={
        <Menu className="min-w-0 lang-menu-scroll">
          {Object.values(Locale)
            .filter((locale) =>
              (available_languages ?? error_data_available_languages)?.some(
                (item: any) => item === locale
              )
            )
            ?.map((locale: Locale) => (
              <MenuItem
                key={locale}
                text={LocaleDisplayedValues[locale]}
                active={
                  locale === selectedLanguage
                  // (isSurveyQuestionsPage ? surveyLocale : selectedLanguage)
                }
                onClick={() => handleLocaleChange(locale)}
              />
            ))}
        </Menu>
      }
    >
      <Tag className="uppercase font-bold" interactive>
        {LocaleDisplayedValues[selectedLanguage]}
      </Tag>
    </Popover>
  ) : (
    <div className={"flex justify-center slickSlider"}>
      <div
        className={
          "slickSliderDiv mt-8 mb-8 sm:w-full sm:px-4 md:px-0 xl:w-2/3"
        }
      >
        <FormGroup
          label={
            <b>
              {`${intl.formatMessage({
                id: "app.titles.language",
              })}:`}
            </b>
          }
        >
          <Slider {...settings}>
            {Object.values(Locale)
              .filter((locale) =>
                (available_languages ?? error_data_available_languages)?.some(
                  (item: any) => item === locale
                )
              )
              ?.map((locale: Locale) => (
                <div className="justify-between">
                  <Card
                    key={locale}
                    interactive
                    tabIndex={-1}
                    role="button"
                    onClick={() => handleLocaleChange(locale)}
                    title={LocaleDisplayedValues[locale]}
                    style={{
                      width: "12rem",
                    }}
                    className={`bg-blue-600 text-white cursor-pointer text-center text-xl ${
                      locale === selectedLanguage ? "font-bold" : undefined
                    }`}
                  >
                    <p className="whitespace-no-wrap">
                      {LocaleDisplayedValues[locale]}
                    </p>
                  </Card>
                </div>
              ))}
          </Slider>
        </FormGroup>
      </div>
    </div>
  );
};
