import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import Slider from "react-slick";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuestions,
  fetchSurvey,
  loadExistingAns,
  loadExistingState,
} from "./store/slices/survey";
import { useParams } from "react-router";
import { Header } from "./components/Header";
import { Layout } from "./pages/Layout";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Main } from "./components/Main";
import { NoPage } from "./components/NoPage";
import Axios from "axios";
import { Locale } from "./types";
import ConfirmDialog from "./components/ConfirmDialog";
import { LandingPage } from "./pages/LandingPage";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import {
  SessionStorageKeys,
  getObjectFromSessionStorage,
  getStringFromSessionStorage,
} from "./constants/session-storage";
import NoInternetConnection from "./NoInternetConnection";

type RouterParams = {
  hash: string;
  school: string;
};

function App() {
  // hooks
  const intl = useIntl();
  const survey = useSelector((s: any) => s.survey);
  const dispatch = useDispatch();
  const [initialState, setInitialState] = useState<any>();

  useEffect(() => {
    const sessionId = getStringFromSessionStorage(
      SessionStorageKeys.SurveySessionHash
    );
    // if (!!sessionId && !initialState){
    //   sessionStorage.setItem(`${sessionId}`, JSON.stringify(survey));
    // }else{
    //   setInitialState(false);
    // }
  }, [survey]);

  return (
    <>
      <NoInternetConnection>
        <Helmet>
          <title>{intl.formatMessage({ id: "app.titles.surveys" })}</title>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <>
                    <p>{"Invalid survey link."}</p>
                  </>
                }
              />
              {/* <Route path="blogs" element={<Blogs />} /> */}
              <Route
                path="app/surveys/questions/:hash/:school/test"
                element={<LandingPage />}
              />
              <Route
                path="app/surveys/questions/:hash/:school/:(test)?"
                element={<LandingPage />}
              />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <ConfirmDialog isOpen={false} />
      </NoInternetConnection>
    </>
  );
}
export default App;