import React from "react";
import FinishSurveyPage from "../components/FinishSurveyPage";
import { getLocalizedInstruction } from "../utils/utils";
import { Locale } from "../types";
import { useDispatch } from "react-redux";
import { navigateUser, resetSurveyCompletion } from "../store/slices/survey";
import { SCREEN_LANGUAGE_SELECT } from "../constants/constants";

type OwnProps = {
  handleUpdateSurveyTimer: any;
  survey: any;
  language: any;
  localizedDemographic: any;
  handleAfterSurveyComplete:any;
  onSurveyComplete:any;
};

type Props = OwnProps;

export const FinishPage = ({
  handleUpdateSurveyTimer,
  survey,
  language,
  localizedDemographic,
  handleAfterSurveyComplete,
  onSurveyComplete
}: Props) => {
  const dispatch = useDispatch();

  //handler
  const handleSurveyCompletedChange = () => {
    dispatch(resetSurveyCompletion());
    handleAfterSurveyComplete();
    dispatch(navigateUser(SCREEN_LANGUAGE_SELECT));
    onSurveyComplete();
  };

  return (
    <FinishSurveyPage
      onReattemptSurveyClick={handleSurveyCompletedChange}
      onUpdateTimer={handleUpdateSurveyTimer}
      demographic={localizedDemographic}
      schoolName={survey?.school_name}
      instruction={getLocalizedInstruction(language as Locale, "post", survey)}
    />
  );
};
