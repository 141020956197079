import React, { useEffect, useMemo } from "react";
import { Card, FormGroup, Spinner } from "@blueprintjs/core";
import NjSciLogo from "../components/NjSciLogo";
import {
  LanguageSelector,
  LocaleDisplayedValues,
} from "../components/LanguageSelector";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Slider, { Settings } from "react-slick";
import useWindowSize, { WindowSize } from "../helpers/hooks/useWindowSize";
import CustomArrow from "../components/CustomArrow";
import { Locale, SurveyDemographic, Timer } from "../types";
import {
  navigateUser,
  updateLanguage,
  updateSelectedLanguage,
} from "../store/slices/survey";
import { SCREEN_ERR, SCREEN_PASSWORD_REQ } from "../constants/constants";
import {
  SessionStorageKeys,
  getArrayFromSessionStorage,
  getBooleanFromSessionStorage,
  getStringFromSessionStorage,
} from "../constants/session-storage";
import { getStringFromLocalStorage } from "../constants";
import { useWindowUnload } from "../helpers/hooks/useWindowUnload";
import moment from "moment";

export const LanguagePage = () => {
  const EXPIRED_ERROR_MESSAGE = "Survey time has expired";

  //hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const availableLanguages = useSelector(
    (s: any) => s.survey.data.completedSurvey?.available_languages
  );
  const survey = useSelector((s: any) => s.survey.data?.completedSurvey);
  const selectedLanguage = useSelector((s: any) => s.survey.ui.language);
  const isSurveyLanguageSelected = useSelector(
    (s: any) => s.survey.ui.isSurveyLanguageSelected
  );
  const expiredError = useSelector(
    (s: any) => s.survey.data.surveyExpiredError
  );
  // useSelector
  const error_data_available_languages = useSelector(
    (s: any) => s.survey.data?.errorData?.available_languages
  );

  // number of slides to show in react-slick
  const slidesToShow = useMemo(() => {
    const itemsLength: number = (availableLanguages??error_data_available_languages)?.length;
    switch (windowSize) {
      case WindowSize.SM:
        return 1;
      case WindowSize.MD:
        return itemsLength === 1 ? 1 : 2; //return menuCollapsed ? 1 : itemsLength === 1 ? 1 : 2; - removed
      default:
        return itemsLength < 5 ? itemsLength : 5;
    }
  }, [windowSize, availableLanguages]);

  // configuration for react-slick
  const settings: Settings = useMemo(
    () => ({
      dots: slidesToShow == 1 ? false : true,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      prevArrow: <CustomArrow isPrev />,
      nextArrow: <CustomArrow />,
    }),
    []
  );

  // handlers
  const handleLanguageUpdate = (language: string) => {
    // debugger
    dispatch(updateLanguage({ language: language, navigate: true }));
  };

  // useEffect
  useEffect(() => {
    if (survey?.demographic == SurveyDemographic.SchoolStaff) {
      dispatch(updateSelectedLanguage(Locale.English));
    }
  }, [survey?.demographic]);

  // useEffect
  useEffect(() => {
    const selected = getBooleanFromSessionStorage(
      SessionStorageKeys.IsSurveyLanguageSelected
    );
    // debugger
    if (selected) {
      let lang = sessionStorage.getItem(SessionStorageKeys.Locale);
      dispatch(updateSelectedLanguage(lang));
    }
  }, []);

  // useEffect
  useEffect(() => {
    // debugger
    if(expiredError && expiredError === EXPIRED_ERROR_MESSAGE){
      dispatch(navigateUser(SCREEN_ERR));
    }else if (isSurveyLanguageSelected) {
      dispatch(navigateUser(SCREEN_PASSWORD_REQ));
    }
  }, [isSurveyLanguageSelected]);

  return (
    <>
      <NjSciLogo />
      <div className="flex justify-center slickSlider">
        <div className="slickSliderDiv mt-8 mb-8 sm:w-full sm:px-4 md:px-0 xl:w-2/3">
          <FormGroup
            label={
              <b>
                {`${intl.formatMessage({
                  id: "app.titles.language",
                })}:`}
              </b>
            }
          >
            <Slider {...settings}>
              {Object.values(Locale)
                .filter((locale) =>
                (availableLanguages??error_data_available_languages)?.some((item: any) => item === locale)
                )
                ?.map((locale: Locale) => (
                  <div className="justify-between">
                    <Card
                      key={locale}
                      interactive
                      tabIndex={-1}
                      role="button"
                      onClick={() => handleLanguageUpdate(locale)}
                      title={LocaleDisplayedValues[locale]}
                      style={{
                        width: "12rem",
                      }}
                      className={`bg-blue-600 text-white cursor-pointer text-center text-xl ${
                        locale === selectedLanguage ? "font-bold" : undefined
                      }`}
                    >
                      <p className="whitespace-no-wrap">
                        {LocaleDisplayedValues[locale]}
                      </p>
                    </Card>
                  </div>
                ))}
            </Slider>
          </FormGroup>
        </div>
      </div>
    </>
  );
};
