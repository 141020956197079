import React, { useCallback, useEffect, useMemo, useState } from "react";
import Stepper from "./Stepper";
import { Button, Icon, Spinner } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import SurveyTitle from "./SurveyTitle";
import { useIntl } from "react-intl";
import { getLocalizedInstruction, getLocalizedStatement } from "../utils/utils";
import {
  SessionStorageKeys,
  getNumberFromSessionStorage,
  getStringFromSessionStorage,
} from "../constants/session-storage";
import _ from "lodash";
import SurveyQuestionCard from "./SurveyQuestionCard";
import SurveyQuestionCardHeader, {
  SurveyFormRefs,
} from "./SurveyQuestionCardHeader";
// import { SurveyFormRefs } from "./";
import {
  CompletedDomain,
  Locale,
  SurveyDemographic,
  SurveyQuestion,
  Timer,
} from "../types";
import {
  hideConfirmDialog,
  hideLanguageSelector,
  saveAnswer,
  saveTestingSurveyQuestionAnswer,
  showConfirmDialog,
  surveyCompleted,
  updateSelectedLanguage,
  userTestSurveyComplete,
} from "../store/slices/survey";
import InfoSVG from "./../static/images/infoIcon.svg";
import RespondentInfoForm from "./RespondentInfoForm";
import moment from "moment";
import { useWindowUnload } from "../helpers/hooks/useWindowUnload";
import { LocaleEnglishValues } from "./LanguageSelector";
import { SCREEN_FINISH } from "../constants/constants";
import { TrackAction } from "../helpers/tracker";

export const SurveyQuesPanel = ({
  showLanguageSelector,
  demographic,
  locale,
  direction,
  hash,
  school,
  onUpdateTimer,
  surveyTimer,
  onSurveyFinished,
  isTesting,
}: any) => {
  const intl = useIntl();
  const answers = useSelector((s: any) => s.survey.data.surveyAnswers);
  const survey = useSelector((s: any) => s.survey.data.completedSurvey);

  const [stickyVisible, setStickyVisible] = useState<boolean>(true);
  const [selectedPage, setSelectedPage] = useState<number>(
    getNumberFromSessionStorage(SessionStorageKeys.SurveyCurrentPage) ?? 0
  );
  const [selectedPageNotAnsweredQuestions, setNotAnsweredQuestions] = useState<
    number[]
  >([]);
  const [surveyCompletion, setSurveyCompletion] = useState<any>({
    grade: undefined,
    language: undefined,
    gender: [],
    race: [],
    direct_instruction: undefined,
    remote_learning: undefined,
    answers: [],
    survey_time: [],
  });
  useEffect(() => {
    setSurveyCompletion({
      ...surveyCompletion,
      answers: answers,
    });
  }, [answers]);

  const timer = useMemo<Timer>(
    () => ({
      page: selectedPage + 1,
      time_start: moment().format(),
    }),
    [selectedPage]
  );

  const dispatch = useDispatch();

  const loading = useSelector(
    (s: any) => s.survey.loading.getSurveyQuestionsByHash
  );
  const completedSurvey = useSelector(
    (s: any) => s.survey.data.completedSurvey
  );

  // const isTesting = useSelector((s: any) => s.survey.ui.isTesting);
  const setEndDateToTimer = () => {
    onUpdateTimer({ ...timer, time_end: moment().format() });
  };
  useWindowUnload({ onBeforeUnload: setEndDateToTimer });

  const showDemographicData = useMemo(() => {
    return (
      completedSurvey?.collected_demographic_data?.demographic_data?.length !==
      0
    );
  }, [completedSurvey]);

  const surveyQuestionsPages = useMemo(() => {
    if (completedSurvey?.survey_questions?.length) {
      return _.mapValues(
        _.groupBy(completedSurvey?.survey_questions, (sq) => sq.page),
        (v) => _.sortBy(v, "order")
      );
    }
    return {};
  }, [completedSurvey]);

  const totalPages: number = useMemo(() => {
    const keys = Object.keys(surveyQuestionsPages);
    return (showDemographicData ? keys.length : keys.length - 1) ?? 0;
  }, [surveyQuestionsPages, showDemographicData]);

  const isDemographicDataPage = useMemo(() => {
    return totalPages === selectedPage && showDemographicData;
  }, [totalPages, selectedPage, showDemographicData]);

  useEffect(() => {
    // if(isDemographicDataPage){
    //   dispatch(hideLanguageSelector(true));
    // }
    // else
    // dispatch(hideLanguageSelector(!showLanguageSelector));
    if (survey?.demographic !== SurveyDemographic.SchoolStaff) {
      if (isDemographicDataPage) {
        dispatch(hideLanguageSelector(true));
      } else dispatch(hideLanguageSelector(false));
    } else {
      dispatch(hideLanguageSelector(true));
    }
  }, [isDemographicDataPage, selectedPage, showLanguageSelector]); //isDemographicDataPage]);

  useEffect(()=>{
    (async()=>{
      await TrackAction({
        action:'surveyQuestionTrackerUpdate',
        other:JSON.stringify({
          survey_time: [
            ...surveyTimer,
            { ...timer, time_end: moment().format() },
          ],
          language: LocaleEnglishValues[locale],
        })
      })
    })()
  },[surveyTimer, timer]);


  useEffect(()=>{
      (async()=>{
        await TrackAction({
          action:'pageChange',
          other:JSON.stringify({
            selectedPage: selectedPage + 1
          })
        })
      })()
  },[selectedPage])

  const [refs, setRefs] = useState<SurveyFormRefs>();
  useEffect(() => {
    let refs: SurveyFormRefs = { leadIns: [], separators: [] };
    surveyQuestionsPages[selectedPage]?.forEach((sq, index, array) => {
      if (
        (index === 0 && sq.lead_in) ||
        (index !== 0 && array[index - 1].lead_in !== sq.lead_in && sq.lead_in)
      ) {
        refs.leadIns.push({ sqId: sq.id, ref: React.createRef() });
      }
      if (index !== 0 && !sq.lead_in && array[index - 1].lead_in) {
        refs.separators.push({ sqId: sq.id, ref: React.createRef() });
      }
    });
    setRefs(refs);
  }, [selectedPage, surveyQuestionsPages]);

  const handleQuestionAnswerClick = (
    index: number,
    surveyQuestion: SurveyQuestion<number, CompletedDomain>
  ) => {
    // debugger
    if (selectedPageNotAnsweredQuestions.includes(surveyQuestion.id)) {
      setNotAnsweredQuestions(
        selectedPageNotAnsweredQuestions.filter(
          (item) => item !== surveyQuestion.id
        )
      );
    }
    const selectedAnswer = answers?.find(
      (a: any) => a.question_id === surveyQuestion.id
    );
    if (!!selectedAnswer) {
      const updatedAnswer = {
        ...selectedAnswer,
        answer_index:
          selectedAnswer.answer_index === index
            ? selectedAnswer.answer_index === undefined
              ? index
              : undefined
            : index,
      };
      if (isTesting) {
        dispatch(saveTestingSurveyQuestionAnswer(updatedAnswer));
      } else {
        dispatch(
          saveAnswer({
            sessionHash:
              getStringFromSessionStorage(
                SessionStorageKeys.SurveySessionHash
              ) ?? "",
            key: hash,
            password: getStringFromSessionStorage(
              SessionStorageKeys.SurveyPassword
            ),
            school: school,
            locale: locale,
            reqData: {
              request: updatedAnswer,
            },
            previousObject: selectedAnswer,
          })
          // saveSurveyQuestionAnswer.request({
          //   hash: hash,
          //   school: school,
          //   sessionHash:
          //     getStringFromSessionStorage(
          //       SessionStorageKeys.SurveySessionHash
          //     ) ?? "",
          //   request: updatedAnswer,
          //   previousObject: selectedAnswer,
          // })
        );
      }
    }
  };

  const getAnswerOptions = useCallback(
    (
      sq: SurveyQuestion<number, CompletedDomain>
    ): { key: number; value: string }[] => {
      const englishAnswers = sq?.domain?.answers[Locale.English];
      let answers: { key: number; value: string }[] = [];
      if (!!sq?.domain?.answers) {
        const validAnswers = sq?.domain?.answers[locale] ?? englishAnswers;

        answers = Object.keys(validAnswers)
          .map((v) => parseInt(v))
          .filter((index) => {
            if (!sq.is_not_applicable_answer) {
              return index >= 0;
            }
            return true;
          })
          .map((index) => {
            const item = validAnswers[index];
            if (item === "") {
              return {
                key: index,
                value: Object.values(englishAnswers)[index],
              };
            }
            return {
              key: index,
              value: item,
            };
          });
      }

      return answers;
    },
    [surveyQuestionsPages, selectedPage, locale]
  );

  let separatorsY;
  let leadInsY;

  const handleScroll = useCallback(() => {
    separatorsY = refs?.separators?.map(
      (sr) => sr.ref.current?.getBoundingClientRect()?.y
    );
    leadInsY = refs?.leadIns?.map(
      (lr) => lr.ref.current?.getBoundingClientRect()?.y
    );
    if (separatorsY !== undefined && leadInsY !== undefined) {
      for (let i = separatorsY.length - 1; i >= 0; i--) {
        if (separatorsY[i] > 0 && leadInsY[i] > 0) {
          continue;
        }
        if (separatorsY[i] < 0 && leadInsY[i] < 0) {
          setStickyVisible(false);
          return;
        }
        if (separatorsY[i] > 0 && leadInsY[i] < 0) {
          setStickyVisible(true);
          return;
        }
      }
    }
  }, [stickyVisible, refs]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const isAnswerActive = useCallback(
    (
      surveyQuestion: SurveyQuestion<number, CompletedDomain>,
      index: number
    ) => {
      return (
        answers?.find((a: any) => a.question_id === surveyQuestion.id)
          ?.answer_index === index
      );
    },
    [answers]
  );

  const getCurrentPageNotAnsweredQuestionIds = useCallback(() => {
    const selectedPageQuestionIds: number[] = _.map(
      surveyQuestionsPages[selectedPage],
      "id"
    );
    return _.map(
      _.filter(answers, (sq) => {
        return selectedPageQuestionIds.some(
          (item) => item === sq.question_id && sq.answer_index === undefined
        );
      }),
      "question_id"
    );
  }, [selectedPage, surveyQuestionsPages, answers]);

  const handlePageChangeConfirm = (page: number) => {
    setEndDateToTimer();
    sessionStorage.setItem(
      SessionStorageKeys.SurveyCurrentPage,
      JSON.stringify(page)
    );
    setSelectedPage(page);

    if (!("scrollBehavior" in document.documentElement.style)) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 600);
    } else {
      setTimeout(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }, 600);
    }
  };

  const handlePageChangeCancel = (notAnsweredQuestionIds: number[]) => {
    dispatch(hideConfirmDialog());
    setNotAnsweredQuestions(notAnsweredQuestionIds);
  };

  const handleCompletedSurveyChange = (updatedCompletedSurvey: any) => {
    setSurveyCompletion(updatedCompletedSurvey);
  };

  const handleCompletedSurveySubmitSuccess = () => {
    // onSurveyFinished(true);
    dispatch(userTestSurveyComplete());
  };

  const handleFormSubmit = () => {
    setEndDateToTimer();
    if (isTesting) {
      handleCompletedSurveySubmitSuccess();
    } else {
      dispatch(
        surveyCompleted({
          locale: locale,
          key: hash,
          completedSurvey: {
            ...surveyCompletion,
            survey_time: [
              ...surveyTimer,
              { ...timer, time_end: moment().format() },
            ],
            language: LocaleEnglishValues[locale],
          },
          password: getStringFromSessionStorage(
            SessionStorageKeys.SurveyPassword
          ),
          school: school,
          sessionHash:
            getStringFromSessionStorage(SessionStorageKeys.SurveySessionHash) ??
            "",
        })
      );
    }
  };

  const handlePageChange = (page: number) => {
    // debugger
    const notAnsweredQuestionIds = getCurrentPageNotAnsweredQuestionIds();
    if (notAnsweredQuestionIds?.length > 0) {
      dispatch(
        showConfirmDialog({
          show: true,
          icon: "info-sign",
          intent: "warning",
          cancelButtonText: intl.formatMessage({
            id: "app.confirmation-dialogs.answer-questions",
          }),
          confirmButtonText: intl.formatMessage({
            id: "app.confirmation-dialogs.continue-without-answering",
          }),
          text: intl.formatMessage({
            id: "app.confirmation-dialogs.change-not-answered-page",
          }),
          onConfirm: () => {
            dispatch(hideConfirmDialog());
            handlePageChangeConfirm(page);
          },
          onCancel: () => handlePageChangeCancel(notAnsweredQuestionIds),
        })
      );
    } else {
      handlePageChangeConfirm(page);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="flex justify-center">
        <div className="question-container-max-width w-full">
          <SurveyTitle
            className="welcome-page-title text-blue-600 text-center"
            schoolName={
              completedSurvey?.school?.name || completedSurvey?.school_name
            }
            demographic={demographic}
          />

          {!!totalPages && (
            <Stepper
              direction={direction}
              totalSteps={totalPages}
              currentStep={selectedPage}
              onClick={handlePageChange}
            />
          )}

          <div className={`text-2xl text-blue-600 font-medium my-8 mx-4`}>
            {getLocalizedInstruction(
              locale,
              isDemographicDataPage ? "demographic_data" : selectedPage,
              completedSurvey
            )}
          </div>

          <div
            className={`text-xl sm:text-2xl text-blue-600 font-bold my-4 mx-4`}
          >
            {getLocalizedStatement(
              locale,
              isDemographicDataPage ? "demographic_data" : selectedPage,
              completedSurvey
            )}
          </div>

          <div className="space-y-8">
            {surveyQuestionsPages[selectedPage]?.map((sq, index) => (
              <div key={index}>
                <SurveyQuestionCardHeader
                  direction={direction}
                  surveyQuestion={sq}
                  locale={locale}
                  refs={refs}
                  stickyVisible={stickyVisible}
                />
                <SurveyQuestionCard
                  key={index}
                  direction={direction}
                  isNotAnswered={selectedPageNotAnsweredQuestions.includes(
                    sq.id
                  )}
                  surveyQuestion={sq}
                  hash={hash}
                  school={school}
                  locale={locale}
                  answerOptions={getAnswerOptions(sq)}
                  isAnswerActive={isAnswerActive}
                  onQuestionAnswerClick={handleQuestionAnswerClick}
                />
              </div>
            ))}
            {isDemographicDataPage && (
              <div className="mx-4">
                <RespondentInfoForm
                  direction={direction}
                  collectedDemographicData={
                    completedSurvey?.collected_demographic_data
                      ?.demographic_data
                  }
                  completedSurvey={surveyCompletion}
                  onCompletedSurveyChange={handleCompletedSurveyChange}
                  availableGrades={completedSurvey?.school?.grades}
                  demographic={completedSurvey?.demographic}
                />
              </div>
            )}
            <div className="flex justify-between mt-4" dir={direction}>
              <Button
                intent="primary"
                icon={
                  <Icon
                    icon={direction === "rtl" ? "arrow-right" : "arrow-left"}
                    iconSize={26}
                  />
                }
                className="survey-arrows-button-size navbtn"
                disabled={selectedPage === 0}
                onClick={() => handlePageChange(selectedPage - 1)}
              />
              <Button
                intent="primary"
                className="survey-arrows-button-size navbtn"
                icon={
                  <Icon
                    icon={direction === "rtl" ? "arrow-left" : "arrow-right"}
                    iconSize={26}
                  />
                }
                onClick={() => {
                  selectedPage === totalPages
                    ? handleFormSubmit()
                    : handlePageChange(selectedPage + 1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
