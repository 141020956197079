import React, { useEffect } from "react";
import usePrevProps from "./usePrevProps";
import { AppToaster } from "../toaster";
import { getErrorMessage } from "../message-format-utils";
import _ from "lodash";

export type UseLoadingType = {
  loading?: boolean;
  error?: Error;
  errorTitle?: string | ((error?: Error) => string | undefined);
  onSuccess?: () => void;
  onError?: (error?: Error) => void;
  notShowDefaultToaster?: boolean;
};

export const showErrorAlert = (
  error?: Error,
  errorTitle?: string | ((error?: Error) => string | undefined)
) => {
  const { title, message } = getErrorMessage(error);

  const customTitle = errorTitle
    ? _.isFunction(errorTitle)
      ? errorTitle(error)
      : errorTitle
    : undefined;

  AppToaster.show({
    message: (
      <>
        <p className="font-bold">{customTitle ?? title}</p>
        <p>
          {message.split("\n").map((mesPart: string, index: number) => (
            <>
              {!!index && <br />}
              {mesPart}
            </>
          ))}
        </p>
      </>
    ),
    intent: "danger",
  });
};

export const useLoading = ({
  loading,
  error,
  onSuccess,
  onError,
  notShowDefaultToaster,
  errorTitle,
}: UseLoadingType) => {
  const prevProps = usePrevProps({ loading: loading });

  useEffect(() => {
    if (prevProps?.loading && !loading) {
      if (error) {
        if (!notShowDefaultToaster) {
          showErrorAlert(error, errorTitle);
        }
        onError && onError(error);
      } else {
        onSuccess && onSuccess();
      }
    }
  }, [loading, prevProps?.loading]);
};
