import { useEffect, useState } from "react";

export enum WindowSize {
  SM = "sm",
  MD = "md",
  LG = "lg",
  XLG = "xlg",
}

const Sizes = {
  [WindowSize.SM]: 640,
  [WindowSize.MD]: 1024,
  [WindowSize.LG]: 1280,
};

export const getBreakPoint = (windowWidth: number) => {
  if (windowWidth) {
    if (windowWidth < Sizes[WindowSize.SM]) {
      return WindowSize.SM;
    } else if (windowWidth < Sizes[WindowSize.MD]) {
      return WindowSize.MD;
    } else if (windowWidth < Sizes[WindowSize.LG]) {
      return WindowSize.LG;
    } else {
      return WindowSize.XLG;
    }
  } else {
    return undefined;
  }
};

const useWindowSize = () => {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? getBreakPoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth));
    }

    if (isWindowClient) {
      
      window.addEventListener("resize", setSize);
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
};

export default useWindowSize;
