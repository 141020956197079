import React, { useState, useEffect } from 'react';
import { AppToaster } from './helpers/toaster';

const NoInternetConnection = (props: any): any => {

    const [isOnline, setOnline] = useState(true);


    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

   
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });


    if (isOnline) {
        return (
            props.children
        )
    } else {
       AppToaster.show({
            message: "Network connection lost",
             intent: "danger"
        })
      return <></>  
    }
}

export default NoInternetConnection;