import React from "react";
import { useIntl } from "react-intl";

type OwnProps = {
  className?: string;
  schoolName?: string;
  demographic?: string;
};

type Props = OwnProps;

const SurveyTitle: React.FC<Props> = ({
  className,
  schoolName,
  demographic,
}: Props) => {
  const intl = useIntl();

  return (
    <div
      className={
        !!className
          ? className
          : "welcome-page-title text-blue-600 md:mx-32 mb-0 text-center lh-1"
      }
    >
      {intl.formatMessage({ id: "app.title.on-surveys" })}

      <br />
      <span className={"uppercase survey-school-name"}>{schoolName}</span>
      <br />
      <div className={"uppercase text-blue-second"}>{demographic}</div>
    </div>
  );
};

export default SurveyTitle;
