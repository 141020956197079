import { useEffect } from "react";

type UseWindowUnloadType = {
  onBeforeUnload: () => void;
};

export const useWindowUnload = ({ onBeforeUnload }: UseWindowUnloadType) => {
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      return onBeforeUnload();
    });
    return () => {
      window.removeEventListener("beforeunload", (e) => {
        e.preventDefault();
        return onBeforeUnload();
      });
    };
  }, [onBeforeUnload]);
};
