export enum SessionStorageKeys {
  SurveyCurrentPage = "app.survey:current_page",
  LastSurveyHash = "app.survey:survey_hash",
  LastSurveySchoolHash = "app.survey:survey_school_hash",
  IsSurveyCompleted = "app.survey:completed",
  IsSurveyStarted = "app.survey:started",
  SurveyAns = "app.survey:ans",
  SurveyTimer = "app.survey:timer",
  SurveySessionHash = "app.survey:session_hash",
  SurveyPassword = "app.survey_password:survey_password",
  SurveyPasswordValid = "app.survey_password:survey_password_valid",
  MaxSurveyPageVisited = "app.survey:max_survey_page_visited",
  Locale = "app.ui:survey-language",
  IsSurveyLanguageSelected = "app.survey:is_survey_language_selected",
  surveyTimeOut = "app.survey:survey_time_out",
  Active="app.survey:active"
}

export const getBooleanFromSessionStorage = (
  key: string,
  defaultValue?: boolean
) => {
  return Boolean(
    JSON.parse(sessionStorage.getItem(key) ?? Boolean(defaultValue).toString())
  );
};

export const getObjectFromSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : undefined;
};

export const getNumberFromSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : undefined;
};

export const getStringFromSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(key);
  return item ? String(JSON.parse(item)) : undefined;
};

export function getArrayFromSessionStorage<T>(key: string): Array<T> {
  return Array.from(JSON.parse(sessionStorage.getItem(key) ?? "[]"));
}

export const resetSessionStorage = (keys: string[]) => {
  keys.forEach((key) => sessionStorage.removeItem(key));
};
