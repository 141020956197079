import React, { useEffect, useMemo } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Main } from "../components/Main";
import { Outlet, useLocation } from "react-router";
import { Navbar } from "@blueprintjs/core";
import { useIntl } from "react-intl";
const TEST_URL_ENDING = "test";

export const Layout = () => {
  const location = useLocation();
  const intl = useIntl();

  const isTesting: boolean = useMemo(() => {
    return location.pathname.endsWith(TEST_URL_ENDING);
  }, [location]);


  useEffect(() => {
    if (isTesting) {
      const nodeBody = document.body;
      nodeBody.classList.add("bordered-body");
      nodeBody.removeAttribute("height");
      document.documentElement.classList.add("html-height-auto");
      return () => {
        nodeBody.classList.remove("bordered-body");
        document.documentElement.classList.remove("html-height-auto");
      };
    }
  }, [isTesting]);

  return (
    <div>
      {isTesting && (
        <Navbar fixedToTop className="bg-red-600 z-50 shadow-none">
          <div className="flex justify-center text-3xl font-bold text-white">
            {intl.formatMessage({ id: "app.titles.test-mode" })}
          </div>
        </Navbar>
      )}
      <Header isTesting={isTesting} />
      <div className="flex-grow p-2 body-container">
        <Outlet context={[isTesting]} />
      </div>
      <Footer />
    </div>
  );
};
