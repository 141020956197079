import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SCREEN_ERR,
  SCREEN_FINISH,
  SCREEN_LANGUAGE_SELECT,
  SCREEN_LOADING,
  SCREEN_PASSWORD_REQ,
  SCREEN_QUESTIONS,
} from "../constants/constants";
import { Icon, NonIdealState, Spinner } from "@blueprintjs/core";
import { useOutletContext, useParams } from "react-router";
import {
  fetchSurvey,
  hideLanguageSelector,
  navigateUser,
  resetSurveyCompletion,
  resetSurveyLanguage,
  updateErrorPageMessage,
  updateLanguage,
  updateSelectedLanguage,
} from "../store/slices/survey";
import { LanguagePage } from "./LanguagePage";
import { PasswordPage } from "./PasswordPage";
import { QuestionsPage } from "./QuestionsPage";
import { FinishPage } from "./FinishPage";
import { useIntl } from "react-intl";
import { Locale, SurveyDemographic, Timer } from "../types";
import {
  SessionStorageKeys,
  getArrayFromSessionStorage,
  getBooleanFromSessionStorage,
  getStringFromSessionStorage,
  resetSessionStorage,
} from "../constants/session-storage";
import { getStringFromLocalStorage } from "../constants";
import moment from "moment";
import { AppToaster } from "../helpers/toaster";
import { TrackAction } from "../helpers/tracker";
import { useWindowUnload } from "../helpers/hooks/useWindowUnload";

export const LandingPage = () => {
  const [isTesting]: [boolean] = useOutletContext();

  const EXPIRED_ERROR_MESSAGE = "Survey time has expired";
  const NOT_FOUND_ERROR_MESSAGE = "Not found.";

  // for survey timeout 24 hrs
  const [surveyTimeOut, setSurveyTimeOut] = useState<boolean>(false);
  const TIME_OUT_HOURS: number = 24;
  const TIME_OUT_IN_MILLISECONDS: number = 1000 * 60 * 60 * TIME_OUT_HOURS;

  // hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hash, school } = useParams<any>();
  const {
    activeScreen,
    language,
    isSurveyLoaded,
    isSurveyLanguageSelected,
    isSurveyStarted,
    errorPageMessage,
  } = useSelector((s: any) => s.survey.ui);
  const { loading } = useSelector((s: any) => s.survey);
  const survey = useSelector((s: any) => s.survey.data.completedSurvey);
  const error = useSelector((s: any) => s.survey.error);
  const expiredError = useSelector(
    (s: any) => s.survey.data.surveyExpiredError
  );
  // useSelector
  const errorData = useSelector(
    (s: any) => s.survey.data?.errorData
  );
  const [surveyTimer, setSurveyTimer] = useState<Timer[]>(
    getArrayFromSessionStorage(SessionStorageKeys.SurveyTimer)
  );
  const lastSurveyHash = useMemo(
    () => getStringFromSessionStorage(SessionStorageKeys.LastSurveyHash),
    [hash]
  );
  const lastSurveySchool = useMemo(
    () => getStringFromSessionStorage(SessionStorageKeys.LastSurveySchoolHash),
    [school]
  );

  const onBeforeUnloadFunction = ()=>{
    (async () => {
      await TrackAction({
        action: "onBeforeUnload",
        other: JSON.stringify({
          dateTime:new Date()
        }),
      });
    })();
  }

  useWindowUnload({ onBeforeUnload: onBeforeUnloadFunction });


  // useEffect for timer
  useEffect(() => {
    const surveyTimerLength = surveyTimer?.length;
    if (surveyTimerLength > 0) {
      const lastTimer = surveyTimer[surveyTimerLength - 1];
      if (lastTimer?.page === "post-complete") {
        const currentTime = moment().format();
        const subtractedTime = moment(currentTime).subtract(30, "minutes");
        if (subtractedTime.isAfter(lastTimer.time_start)) {
          resetSurveySessionStorage();
          setSurveyTimer([]);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (lastSurveyHash !== hash || lastSurveySchool !== school) {
      dispatch(resetSurveyLanguage());
      resetSurveySessionStorage();
      setSurveyTimer([]);
      dispatch(resetSurveyCompletion());
    }
  }, [hash, school, lastSurveySchool, lastSurveyHash]);

  // startup function
  const startupFunction = useCallback(() => {
    dispatch(
      fetchSurvey({
        key: hash,
        school: school,
      })
    );
  }, [hash, school]);

  // startup hook
  useEffect(() => {
    startupFunction();
  }, []);

  // direction
  const direction = useMemo(() => {
    return `${intl.formatMessage({
      id: "app.language.direction",
    })}`;
  }, []);

  // localized demographic
  const localizedDemographic = useMemo(() => {
    if (!!survey?.demographic) {
      return intl.formatMessage({
        id: `app.filters.respondent-type.${survey?.demographic}`,
      });
    }
  }, [survey, language]);

  // update timer on every page
  const handleUpdateSurveyTimer = (timer: Timer) => {
    let updatedTimers: Timer[];
    if (timer.page === "intro" && surveyTimer?.length !== 0) {
      updatedTimers = surveyTimer.map((t) => {
        return t.page === "intro" ? timer : t;
      });
    } else {
      updatedTimers = [...surveyTimer, timer];
    }
    setSurveyTimer(updatedTimers);

    sessionStorage.setItem(
      SessionStorageKeys.SurveyTimer,
      JSON.stringify(updatedTimers)
    );
  };

  // common loading state
  const commonLoading = useMemo(() => {
    return (
      loading.getSurveyQuestionsByHash ||
      loading.getSurveyData ||
      loading.getSurveyData ||
      loading.saveCompletedSurvey
    );
  }, [loading]);

  // useMemo for non school hrs students only
  const nonSchoolHours = useMemo(() => {
    const demographic = error?.demographic || survey?.demographic;
    // error?.error?.demographic?.demographicerror?.demographicerror?.demographicerror?.demographic
    const isStudent =
      demographic &&
      [
        SurveyDemographic.ElementaryStudents,
        SurveyDemographic.Students,
      ].includes(demographic);

    if (!isStudent) {
      return false;
    }
    const today = new Date(); // moment()
    // Check if the day of the week is Saturday (6) or Sunday (0)
    if (today?.getDay() === 6 || today?.getDay() === 0) return true;

    let startTimeHrs = "06:00 am";
    let endTimeHrs = "06:00 pm";
    const startTime = moment(startTimeHrs, "HH:mm a"); // moment.tz(startTimeHrs, "HH:mm a", "EST");
    const endTime = moment(endTimeHrs, "HH:mm a"); //moment.tz(endTimeHrs, "HH:mm a", "EST");
    // return !moment.tz("EST").isBetween(startTime, endTime, undefined, "[)");
    return !moment().isBetween(startTime, endTime, undefined, "[)");
  }, [error?.demographic, survey?.demographic]);

  // function used in timout useEffect
  const getSurveyTimeOutValue = () => {
    return sessionStorage.getItem(SessionStorageKeys.surveyTimeOut);
  };

  // function used in timout useEffect
  const getCurrentDateTime = () => {
    const time = moment().toISOString();
    return time;
  };

  // function used in timout useEffect
  const getTimeOfTimeOutInMilliseconds = () => {
    const dateTime = getCurrentDateTime();
    let surveyDateTime = getSurveyTimeOutValue();
    if (surveyDateTime !== null)
      return (
        TIME_OUT_IN_MILLISECONDS -
        (new Date(dateTime).getTime() - new Date(surveyDateTime).getTime())
      );
    else return TIME_OUT_IN_MILLISECONDS;
  };

  const resetSurveySessionStorage = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKeys.LastSurveyHash,
      JSON.stringify(hash)
    );
    sessionStorage.setItem(
      SessionStorageKeys.LastSurveySchoolHash,
      JSON.stringify(school)
    );
    sessionStorage.setItem(
      SessionStorageKeys.IsSurveyLanguageSelected,
      JSON.stringify(false)
    );
    resetSessionStorage(
      Array.from([
        SessionStorageKeys.SurveyCurrentPage,
        SessionStorageKeys.SurveyTimer,
        SessionStorageKeys.IsSurveyCompleted,
        SessionStorageKeys.SurveyPassword,
        SessionStorageKeys.MaxSurveyPageVisited,
        SessionStorageKeys.SurveySessionHash,
        SessionStorageKeys.IsSurveyStarted,
      ])
    );
    (async ()=>{
      await TrackAction({
        action:'resetSurveySessionStorage',
        other:JSON.stringify({hash, school})
      })
    })()
  }, [hash, school]);

  const handleSurveyTimout = () => {
    dispatch(resetSurveyCompletion());
    dispatch(resetSurveyLanguage());
    resetSurveySessionStorage();
    setSurveyTimer([]);
  };

  // useEffect for survey timeout students only
  useEffect(() => {
    let TimeOut: NodeJS.Timeout;
    if (
      survey &&
      [
        SurveyDemographic.ElementaryStudents,
        SurveyDemographic.Students,
      ].includes(survey?.demographic)
    ) {
      const surveyDateTime = getSurveyTimeOutValue();

      if (surveyDateTime === null)
        sessionStorage.setItem(
          SessionStorageKeys.surveyTimeOut,
          getCurrentDateTime() as string
        );
      TimeOut = setTimeout(() => {
        setSurveyTimeOut(true); // call the survey timeout alert
        handleSurveyTimout();
        sessionStorage.removeItem(SessionStorageKeys.surveyTimeOut);
      }, getTimeOfTimeOutInMilliseconds() as number);
    }
    return () => {
      clearInterval(TimeOut);
    };
  }, [survey?.demographic]);

  // handle user navigation
  useEffect(() => {
    // const lastActiveScreen = sessionStorage.getItem(SessionStorageKeys.Active);
    //  dispatch(navigateUser(lastActiveScreen));
    const surveyCompletedSS = getBooleanFromSessionStorage(
      SessionStorageKeys.IsSurveyCompleted
    );

    const isSurveyStartedSS = getBooleanFromSessionStorage(
      SessionStorageKeys.IsSurveyStarted
    );

    const isSurveyLanguageSelectedSS = getBooleanFromSessionStorage(
      SessionStorageKeys.IsSurveyLanguageSelected
    );

    const isPasswordValidSS = getBooleanFromSessionStorage(
      SessionStorageKeys.SurveyPasswordValid
    );

    const surveySessionHashSS = sessionStorage.getItem(
      SessionStorageKeys.SurveySessionHash
    );

    let lang = sessionStorage.getItem(SessionStorageKeys.Locale);
    // check and update language
    if (isSurveyLanguageSelectedSS && language != lang) {
      dispatch(updateSelectedLanguage(lang ?? Locale.English));
    }

    // invalid survey url
    if (expiredError && expiredError === NOT_FOUND_ERROR_MESSAGE) {
      AppToaster.show({ message: "Invalid survey link", intent: "danger" });
      // dispatch(
      //   updateErrorPageMessage(
      //     intl.formatMessage({
      //       id: "app.non-ideal-state.surveys-expired.title",
      //     })
      //   )
      // );
      dispatch(navigateUser(SCREEN_ERR));
    }
    // if survey exp
    else if (expiredError && expiredError === EXPIRED_ERROR_MESSAGE) {
      dispatch(
        updateErrorPageMessage(
          intl.formatMessage({
            id: "app.non-ideal-state.surveys-expired.title",
          })
        )
      );
      // check if the respondent group is staff if not send those to language selector;
      if(errorData?.demographic !== SurveyDemographic.SchoolStaff)
        dispatch(navigateUser(SCREEN_LANGUAGE_SELECT));
      else     
        dispatch(navigateUser(SCREEN_ERR));
    }
    // if timeout
    else if (nonSchoolHours) {
      dispatch(
        updateErrorPageMessage(
          intl.formatMessage({
            id: "app.non-ideal-state.surveys.out-of-school-time.title",
          })
        )
      );
      dispatch(navigateUser(SCREEN_ERR));
    }
    // if non school hrs
    else if (surveyTimeOut) {
      dispatch(
        updateErrorPageMessage(
          intl.formatMessage({
            id: "app.alert.surveys-expired.message",
          })
        )
      );
      dispatch(navigateUser(SCREEN_ERR));
    }
    // if survey completed
    else if (surveyCompletedSS) {
      if (survey?.demographic !== SurveyDemographic.SchoolStaff)
        dispatch(hideLanguageSelector(false));
      else dispatch(hideLanguageSelector(true));

      dispatch(navigateUser(SCREEN_FINISH));
    }
    // if survey is not complete && password valid
    else if (isSurveyStartedSS && isPasswordValidSS) {
      // debugger
      dispatch(navigateUser(SCREEN_QUESTIONS));
    }
    // if language selected || staff
    else if (
      isSurveyLanguageSelectedSS ||
      survey?.demographic == SurveyDemographic.SchoolStaff
    ) {
      // debugger;
      if (survey?.demographic == SurveyDemographic.SchoolStaff) {
        dispatch(hideLanguageSelector(true));
        startupFunction();
      }
      dispatch(navigateUser(SCREEN_PASSWORD_REQ));
    }
    // default
    else {
      dispatch(navigateUser(SCREEN_LANGUAGE_SELECT));
    }

    // if (!isSurveyStarted) {
    //   if (isSurveyLoaded) {
    //     // check if the respondent is staff
    //     if (survey?.demographic !== SurveyDemographic.SchoolStaff && !isSurveyLanguageSelected)
    //       dispatch(navigateUser(SCREEN_LANGUAGE_SELECT));
    //     else dispatch(navigateUser(SCREEN_PASSWORD_REQ));
    //   }
    // }
  }, [nonSchoolHours, surveyTimeOut, expiredError]); //survey]); //isSurveyLoaded]);

  // test function
  useEffect(() => {
    // if(activeScreen == SCREEN_ERR && errorData?.demographic !== SurveyDemographic.SchoolStaff)
    // {
    //   dispatch(hideLanguageSelector(false));
    // }
    // else{
    //   debugger
    //   dispatch(hideLanguageSelector(true));
    // }

    (async ()=>{
      await TrackAction({
        action:'activeScreenChange',
        other:JSON.stringify({activeScreen:activeScreen})
      })
    })()
  }, [activeScreen]);

  // reset all state and session data and reload the page
  const onSurveyComplete = () => {};

  // active screen here to load screens
  return commonLoading ? (
    <div
      className={`p-4 bg-white border border-gray-400 rounded shadow relative`}
    >
      <Spinner intent="none" />
    </div>
  ) : (
    <div
      className={`p-4 bg-white border border-gray-400 rounded shadow relative`}
    >
      {activeScreen === SCREEN_LANGUAGE_SELECT && <LanguagePage />}
      {activeScreen === SCREEN_PASSWORD_REQ && (
        <PasswordPage
          direction={direction}
          localizedDemographic={localizedDemographic}
          survey={survey}
          onUpdateTimer={handleUpdateSurveyTimer}
        />
      )}
      {activeScreen === SCREEN_QUESTIONS && (
        <QuestionsPage
          isTesting={isTesting}
          localizedDemographic={localizedDemographic}
          hash={hash}
          school={school}
          direction={direction}
          handleUpdateSurveyTimer={handleUpdateSurveyTimer}
          surveyTimer={surveyTimer}
        />
      )}
      {activeScreen === SCREEN_FINISH && (
        <FinishPage
          handleAfterSurveyComplete={startupFunction}
          handleUpdateSurveyTimer={handleUpdateSurveyTimer}
          language={language}
          survey={survey}
          localizedDemographic={localizedDemographic}
          onSurveyComplete={onSurveyComplete}
        />
      )}
      {activeScreen === SCREEN_ERR && (
        <NonIdealState
          icon={<Icon icon="warning-sign" intent="warning" iconSize={70} />}
          title={
            errorPageMessage ??
            intl.formatMessage({
              id: "app.titles.error",
            })
          }
        />
      )}
    </div>
  );
};
