import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import LocalizationProvider from "./components/LocalizationProvider";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import { Locale } from "./types";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const apiDomain = `${process.env.REACT_SERVER}`;

Sentry.init({
  dsn: `${process.env.REACT_SENTRY_DNS}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    apiDomain,
    /^https:\/\/yourserver\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Axios.defaults.baseURL = ""; //todo
// Axios.defaults.headers["content-type"] = "application/json";

// const CancelToken = Axios.CancelToken;
// const source = CancelToken.source();
// Axios.create({ cancelToken: source.token });

// Axios.defaults.xsrfCookieName = "csrftoken";
// Axios.defaults.xsrfHeaderName = "X-CSRFToken";
Axios.defaults.withCredentials = false;
// Axios.defaults.headers.common["Accept-Language"] = Locale.English;
// Axios.defaults.headers.common["x-accept-language"] = Locale.English;

root.render(
  <Provider store={store}>
    <LocalizationProvider>
      <App />
    </LocalizationProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
