import React, { useCallback, useEffect } from "react";
import { SurveyQuesPanel } from "../components/SurveyQuesPanel";
import { useDispatch, useSelector } from "react-redux";
import { SurveyDemographic, Timer } from "../types";
import {
  SessionStorageKeys,
  getArrayFromSessionStorage,
  getBooleanFromSessionStorage,
  getStringFromSessionStorage,
} from "../constants/session-storage";
import ConfirmDialog from "../components/ConfirmDialog";
import {
  fetchQuestions,
  hideLanguageSelector,
  loadExistingAns,
  navigateUser,
  updateSelectedLanguage,
  updateSurveyCompleted,
} from "../store/slices/survey";
import { SCREEN_FINISH, SCREEN_PASSWORD_REQ } from "../constants/constants";

type OwnProps = {
  localizedDemographic?: string;
  hash: string | any;
  school: string | any;
  direction: string | any;
  surveyTimer: any;
  handleUpdateSurveyTimer: any;
  isTesting?:boolean;
};
type Props = OwnProps;

export const QuestionsPage = ({
  localizedDemographic,
  hash,
  school,
  direction,
  surveyTimer,
  handleUpdateSurveyTimer,
  isTesting
}: Props) => {
  // hooks
  const dispatch = useDispatch();
  const showLanguageSelector = useSelector(
    (s: any) => s.survey.ui?.showLanguageSelector
  );
  const language = useSelector((s: any) => s.survey.ui.language);
  const isSurveyCompleted = useSelector(
    (s: any) => s.survey.ui.isSurveyCompleted
  );
  const surveyQuestions = useSelector(
    (s: any) => s.survey.data?.completedSurvey?.survey_questions
  );
  const survey = useSelector((s: any) => s.survey.data.completedSurvey);

  // handler
  const handleSurveyCompletedChange = (testMode: boolean) => {

  };

  //
  const fetchSurveyQuestions = useCallback(() => {
    dispatch(
      fetchQuestions({
        demographicHash: hash,
        schoolHash: school,
        sessionHash:
          JSON.stringify(
            getStringFromSessionStorage(SessionStorageKeys.SurveySessionHash)
          ) ?? "",
        locale: language,
        password: getStringFromSessionStorage(
          SessionStorageKeys.SurveyPassword
        ),
      })
    );
  }, [hash, school, language]);

  // check if the password exists and isvalid
  useEffect(() => {
    const isPassValid = getBooleanFromSessionStorage(
      SessionStorageKeys.SurveyPasswordValid
    );
    if (!isPassValid) {
      // navigate user to password page
      dispatch(navigateUser(SCREEN_PASSWORD_REQ));
    }

    // check if survey already completed
    const isSurveyAlreadyCompleted = getBooleanFromSessionStorage(
      SessionStorageKeys.IsSurveyCompleted
    );
    if (isSurveyAlreadyCompleted) {
      dispatch(updateSurveyCompleted(true));
    }

    // if survey already started
    const isSurveyStarted = getBooleanFromSessionStorage(
      SessionStorageKeys.IsSurveyStarted
    );

    // if survey started and state is empty then call this
    if (isSurveyStarted && !surveyQuestions) fetchSurveyQuestions();
  }, []);

  useEffect(() => {
    if (isSurveyCompleted) {
      // if the respondent group is non staff then show the language selector
      if (survey?.demographic !== SurveyDemographic.SchoolStaff)
        dispatch(hideLanguageSelector(false))
      dispatch(navigateUser(SCREEN_FINISH));
    }
  }, [isSurveyCompleted]);

  return (
    <>
      <SurveyQuesPanel
        isTesting={isTesting}
        surveyTimer={surveyTimer}
        showLanguageSelector={showLanguageSelector}
        hash={hash}
        school={school}
        direction={direction}
        demographic={localizedDemographic}
        locale={language}
        onUpdateTimer={handleUpdateSurveyTimer}
        onSurveyFinished={handleSurveyCompletedChange}
      />
    </>
  );
};
