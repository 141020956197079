import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import survey from './slices/survey';

const reducer = combineReducers({
  survey:survey
})
const store = configureStore({
  reducer,
})
export default store;