import React, { useCallback, useEffect, useMemo, useState } from "react";
import NjSciLogo from "../components/NjSciLogo";
import SurveyTitle from "../components/SurveyTitle";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { getLocalizedInstruction } from "../utils/utils";
import { Button, FormGroup, Icon, InputGroup, Toast2 } from "@blueprintjs/core";
import { Locale, Timer } from "../types";
import {
  SessionStorageKeys,
  getArrayFromSessionStorage,
  getBooleanFromSessionStorage,
  getStringFromSessionStorage,
} from "../constants/session-storage";
import {
  fetchQuestions,
  navigateUser,
  updateInvalidPassword,
  updateSurveyStarted,
} from "../store/slices/survey";
import { useParams } from "react-router";
import { SCREEN_QUESTIONS } from "../constants/constants";
import moment from "moment";
import { useWindowUnload } from "../helpers/hooks/useWindowUnload";
import { AppToaster } from "../helpers/toaster";

type OwnProps = {
  localizedDemographic?: string;
  survey: any;
  onUpdateTimer: any;
  direction: string;
};
type Props = OwnProps;

export const PasswordPage = ({
  localizedDemographic,
  survey,
  onUpdateTimer,
  direction,
}: Props) => {
  // parameters fetched from URL
  const { hash, school } = useParams<any>();

  //state
  const [password, setPassword] = useState<string>("");

  //hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const { language, invalidPassword, isSurveyStarted } = useSelector(
    (s: any) => s.survey.ui
  );

  // update timer
  const timer = useMemo<Timer>(() => {
    const timers: Timer[] = getArrayFromSessionStorage(
      SessionStorageKeys.SurveyTimer
    );
    const introTimer = timers.find((t) => t.page === "intro");
    return !!introTimer
      ? introTimer
      : {
          page: "intro",
          time_start: moment().format(),
        };
  }, []);

  const surveyInstruction = useMemo(() => {
    const localizedInstruction = getLocalizedInstruction(
      language as Locale,
      "intro",
      survey
    )
    if(survey && survey?.page_text){
      return survey?.page_text[language]?.instruction;
    }
    else if(localizedInstruction) {
      return localizedInstruction
    }
    else if(!!survey?.page_text?.intro){
     return survey?.page_text?.intro[language]?.instruction
    }
    else {
      return  ""
    }

    // return (survey && survey?.page_text
    //   ? survey?.page_text[language]?.instruction
    //   : getLocalizedInstruction(
    //       language as Locale,
    //       "intro",
    //       survey
    //     )) ?? (survey?.page_text?.intro)
    //   ? survey?.page_text?.intro[language]?.instruction
    //   : "" ?? ""

    // if (survey && survey.page_text && survey.page_text[language]?.instruction) {
    //   return survey.page_text[language]?.instruction;
    // }
    // return "";
  }, [survey, language]);


  // set end date to timer
  const setEndDateToTimer = () => {
    onUpdateTimer({ ...timer, time_end: moment().format() });
  };
  useWindowUnload({ onBeforeUnload: setEndDateToTimer });

  // dispatch function
  const fetchSurveyQuestions = useCallback(() => {
    dispatch(
      fetchQuestions({
        demographicHash: hash,
        schoolHash: school,
        sessionHash:
          JSON.stringify(
            getStringFromSessionStorage(SessionStorageKeys.SurveySessionHash)
          ) ?? "",
        locale: language,
        password: getStringFromSessionStorage(
          SessionStorageKeys.SurveyPassword
        ),
      })
    );
  }, [hash, school, language]);

  // handler form submit
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEndDateToTimer();
    sessionStorage.setItem(
      SessionStorageKeys.SurveyPassword,
      JSON.stringify(password)
    );
    sessionStorage.setItem(
      SessionStorageKeys.SurveyCurrentPage,
      JSON.stringify(0)
    );
    // submit the password
    fetchSurveyQuestions();
  };

  // handler password change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity("");
    setPassword(e.target?.value);
  };

  // error message
  const setErrorMessage = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity(
      intl.formatMessage({ id: "app.validators.password-required" })
    );
  };

  // useEffect
  // useEffect(()=>{
  //   const isSurveyStarted = getBooleanFromSessionStorage(SessionStorageKeys.IsSurveyStarted);
  //   if(isSurveyStarted){
  //     dispatch(updateSurveyStarted());
  //     dispatch(updateInvalidPassword(false));
  //     // load ans data here
  //     fetchSurveyQuestions()
  //   }
  // },[])

  // useEffect
  useEffect(() => {
    if (isSurveyStarted && !invalidPassword) {
      dispatch(navigateUser(SCREEN_QUESTIONS));
    }
  }, [isSurveyStarted]);

  // toast when password not valid
  useEffect(() => {
    if (invalidPassword)
      AppToaster.show({
        message: intl.formatMessage({
          id: "app.toaster.surveys.forms.invalid-password",
        }),
        icon: "error",
        intent: "danger",
        timeout: 3000,
        onDismiss:()=>{
          dispatch(updateInvalidPassword(false));
        }
      });
  }, [invalidPassword]);

  // useEffect
  // useEffect(()=>{
  //   if(!survey.demographic)
  // },[])



  return (
    <>
      <NjSciLogo />
      <SurveyTitle
        schoolName={survey?.school_name} //{!!error ? error?.school_name : completedSurvey?.school?.name}
        demographic={localizedDemographic}
      />

      <div className="my-4">
         <div dangerouslySetInnerHTML={{ __html: surveyInstruction }} />
      </div>
      <form className={"mx-4 my-4 "} onSubmit={handleFormSubmit}>
        <div
          className="flex justify-center mt-4 max-640 pass-center"
          dir={direction}
        >
          {/* <hr /> */}
          <FormGroup
            className="w-full font-bold sm:w-1/2 text-lg mt-4"
            label={intl.formatMessage(
              { id: "app.forms.surveys.passwords" },
              {
                surveyName: localizedDemographic as any,
                b: (a) => (
                  <span className={"uppercase text-blue-second"}>{a}</span>
                ),
              }
            )}
          >
            <InputGroup
              dir={direction}
              className="uppercase"
              value={password}
              placeholder={intl.formatMessage({
                id: "app.forms.surveys.passwords.placeholder",
              })}
              onChange={handlePasswordChange}
              fill
              large
              onInvalid={setErrorMessage}
              required
              title={intl.formatMessage({
                id: "app.validators.password-required",
              })}
            />
          </FormGroup>
        </div>

        <div className="flex justify-center pt-12 pb-24">
          <Button
            intent="primary"
            className="w-1/3 p-4 start-btn"
            icon={<Icon icon={"arrow-right"} iconSize={36} />}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
};
