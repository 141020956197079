import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import _ from "lodash";
import {
  CompletedSurvey,
  Locale,
  QuestionTag,
  Survey,
  SurveyDemographic,
  SurveyPermissionsError,
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyQuestionExcludeBaseModelType,
} from "../../types";
import {
  SessionStorageKeys,
  getArrayFromSessionStorage,
  getBooleanFromSessionStorage,
  getStringFromSessionStorage,
  resetSessionStorage,
} from "../../constants/session-storage";
import {
  SCREEN_FINISH,
  SCREEN_LANGUAGE_SELECT,
  SCREEN_LOADING,
  SCREEN_PASSWORD_REQ,
  SCREEN_QUESTIONS,
} from "../../constants/constants";
import { stat } from "fs/promises";
import { AppToaster } from "../../helpers/toaster";
import { TrackAction } from "../../helpers/tracker";

type ActionName =
  | "getSurveyQuestions"
  | "saveAnswer"
  | "passwordFilled"
  | "saveSurveyQuestionAnswer";

// loading api root from env
const API_ROOT = process.env.REACT_APP_API_ROOT;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.code == AxiosError.ERR_NETWORK) {
      AppToaster.show({ message: error.message, intent: "danger" });
    } else if (
      error.status != 401 &&
      error?.response?.status != 401 &&
      error.status != 404 &&
      error?.response?.status != 404
    ) {
      AppToaster.show({ message: "Something went wrong", intent: "danger" });
    }

    (async () => {
      await TrackAction({
        action: "apiResponseFailure",
        other: JSON.stringify({
          url: error?.request?.responseURL,
          data: error?.response?.data,
          status: error?.response?.status,
        }),
      });
    })();

    return Promise.reject(error);
  }
);

export const fetchSurvey: any = createAsyncThunk(
  "survey/fetchSurvey",
  async (data: any, { rejectWithValue }) => {
    try {
      const apiUrl = `${API_ROOT}/surveys/questions/first?key=${data.key}&school=${data.school}`;
      const response = await Axios.get(apiUrl);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e?.response);
    }
  }
);

export const languageChange: any = createAsyncThunk(
  "survey/languageChange",
  async (data: any) => {
    const headers = {
      "Content-Type": "application/json",
      "x-accept-language": data.locale,
    };
    const apiUrl = `${API_ROOT}/surveys/questions/language-change?session=${data.sessionHash}&key=${data.key}&school=${data.school}&survey-password=${data.password}`;
    const response = await Axios.get(apiUrl);
    return response.data;
  }
);

export const fetchQuestions: any = createAsyncThunk(
  "survey/fetchQuestions",
  async (data: any) => {
    const apiUrl = `${API_ROOT}/surveys/questions?key=${data.demographicHash}&school=${data.schoolHash}&survey-password=${data.password}`;
    const response = await Axios.get(apiUrl);
    return response.data;
  }
);

export const saveAnswer: any = createAsyncThunk(
  "survey/saveAnswer",
  async (data: any) => {
    const headers = {
      "Content-Type": "application/json",
      "x-accept-language": data.locale,
    };
    const apiUrl = `${API_ROOT}/question/completed?session=${data.sessionHash}&key=${data.key}&school=${data.school}&survey-password=${data.password}`;
    const response = await Axios.put(
      apiUrl,
      { ...data?.reqData?.request },
      { headers: headers }
    );
    return {
      data: response.data,
      ...data,
    };
  }
);

export const surveyCompleted: any = createAsyncThunk(
  "survey/SurveyCompleted",
  async (data: any) => {
    const headers = {
      "Content-Type": "application/json",
      "x-accept-language": data.locale,
    };
    // &survey-password=${data.password}
    const apiUrl = `${API_ROOT}/surveys/completed?key=${data.key}&school=${data.school}&session=${data.sessionHash}`;
    const response = await Axios.post(
      apiUrl,
      { ...data?.completedSurvey },
      { headers: headers }
    );
    return {
      data: response.data,
      ...data,
    };
  }
);

export type SurveysState = {
  data: {
    showSurveyUpsertDialog?: boolean;
    surveys: Survey[];
    selectedSurvey?: Survey;
    surveyQuestions: SurveyQuestionExcludeBaseModelType[];
    selectedSurveyQuestions?: SurveyQuestion[];
    questionTags: QuestionTag[];
    selectedQuestionTag?: QuestionTag;
    completedSurvey?: CompletedSurvey;
    surveyAnswers: SurveyQuestionAnswer[];
    surveyPermissionsError?: SurveyPermissionsError;
    surveyExpiredError?: string;
    errorData?:
      | {
          available_languages: string;
          demographic: string;
          "error-message": string;
          school_name: string;
          survey_name: string;
        }
      | {};
  };
  ui:
    | {
        activeScreen: string;
        language: string | Locale;
        selectedLanguage: string;
        isSurveyLanguageSelected: boolean;
        isSurveyStarted: boolean;
        confirmDialog: any;
        isTesting: boolean;
        isSurveyCompleted: boolean;
        invalidPassword: boolean;
        showLanguageSelector: boolean;
      }
    | any;
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error | boolean;
  };
};

const initialState: SurveysState = {
  data: {
    surveys: [],
    surveyQuestions: [],
    questionTags: [],
    surveyAnswers: [],
    errorData: {},
  },
  ui: {
    activeScreen: "",
    language: "",
    navbar: {
      languageSelectorHidden: false,
    },
    confirmDialog: {},
    isSurveyLanguageSelected: false,
    errorPageMessage: "",
  },
  loading: {},
  errors: {},
};

// Slice
const slice = createSlice({
  name: "survey",
  initialState: initialState,
  reducers: {
    loadExistingState: (state, action) => {
      state = action.payload;
    },
    updateSelectedLanguage: (state, action) => {
      // Axios.defaults.headers.common["Accept-Language"] = action.payload;
      // Axios.defaults.headers.common["x-accept-language"] = action.payload;
      if (action.payload)
        sessionStorage.setItem(SessionStorageKeys.Locale, action.payload);
      sessionStorage.setItem(
        SessionStorageKeys.IsSurveyLanguageSelected,
        `true`
      );
      state["ui"]["language"] = action.payload;
      state["ui"]["isSurveyLanguageSelected"] = true;
    },
    showConfirmDialog: (state, action) => {
      state["ui"]["confirmDialog"] = action.payload;
    },
    hideConfirmDialog: (state) => {
      state["ui"]["confirmDialog"] = {};
    },
    resetSurveyLanguage: (state) => {
      // Axios.defaults.headers.common["Accept-Language"] = Locale.English;
      // Axios.defaults.headers.common["x-accept-language"] = Locale.English;
      sessionStorage.setItem(SessionStorageKeys.Locale, Locale.English);
      sessionStorage.setItem(
        SessionStorageKeys.IsSurveyLanguageSelected,
        "false"
      );
      state["ui"]["language"] = Locale.English;
      state["ui"]["isSurveyLanguageSelected"] = false;
    },
    resetSurveyCompletion: (state) => {
      resetSessionStorage([
        SessionStorageKeys.SurveyAns,
        SessionStorageKeys.SurveyCurrentPage,
        SessionStorageKeys.SurveySessionHash,
        SessionStorageKeys.MaxSurveyPageVisited,
        SessionStorageKeys.IsSurveyCompleted,
        SessionStorageKeys.SurveyPassword,
        SessionStorageKeys.SurveyPasswordValid,
        SessionStorageKeys.SurveyTimer,
        SessionStorageKeys.surveyTimeOut,
        SessionStorageKeys.IsSurveyLanguageSelected,
        SessionStorageKeys.Locale,
        SessionStorageKeys.IsSurveyStarted,
        SessionStorageKeys.IsSurveyCompleted,
      ]);
      // state = initialState;
      state["data"] = {
        surveys: [],
        surveyQuestions: [],
        questionTags: [],
        surveyAnswers: [],
      };
      state["ui"]["activeScreen"] = SCREEN_LANGUAGE_SELECT;
      state["ui"]["language"] = "en";
      state["ui"]["isSurveyStarted"] = false;
      state["ui"]["isSurveyLoaded"] = false;
      state["ui"]["invalidPassword"] = undefined;
      state["ui"]["isSurveyLanguageSelected"] = false;
      state["ui"]["isSurveyCompleted"] = false;
    },
    updateSurveyStarted: (state) => {
      state["ui"]["isSurveyStarted"] = true;
    },
    updateInvalidPassword: (state, action) => {
      state["ui"]["invalidPassword"] = action.payload;
    },

    saveTestingSurveyQuestionAnswer: (state, action) => {
      let answers = state.data.surveyAnswers?.map((sa) => {
        if (sa.question_id === action.payload?.question_id) {
          return action.payload;
        }
        return sa;
      });
      sessionStorage.setItem(SessionStorageKeys.IsSurveyStarted, `true`);
      sessionStorage.setItem(
        `${SessionStorageKeys.SurveyAns}`,
        JSON.stringify(answers)
      );
      return {
        ...state,
        data: {
          ...state.data,
          surveyAnswers: answers,
        },
      };
    },
    hideLanguageSelector: (state, action) => {
      state["ui"]["showLanguageSelector"] = !action.payload;
    },
    loadExistingAns: (state, action) => {
      state["data"]["surveyAnswers"] = action.payload;
    },

    navigateUser: (state, action) => {
      // sessionStorage.setItem(SessionStorageKeys.Active, action.payload);
      state["ui"]["activeScreen"] = action.payload;
    },
    ///
    updateLanguage: (state, action) => {
      // update in session
      sessionStorage.setItem(
        SessionStorageKeys.Locale,
        action.payload.language
      );
      sessionStorage.setItem(
        SessionStorageKeys.IsSurveyLanguageSelected,
        JSON.stringify(true)
      );
      // if (action.payload.navigate) {
      // sessionStorage.setItem(SessionStorageKeys.Active, SCREEN_PASSWORD_REQ);
      // state["ui"]["activeScreen"] = SCREEN_PASSWORD_REQ;
      // }
      state["ui"]["language"] = action.payload.language;
      state["ui"]["isSurveyLanguageSelected"] = true;
    },
    updateSurveyCompleted: (state, action) => {
      state["ui"]["isSurveyCompleted"] = action.payload;
    },
    updateErrorPageMessage: (state, action) => {
      state["ui"]["errorPageMessage"] = action.payload;
    },
    userTestSurveyComplete: (state) => {
      sessionStorage.setItem(
        SessionStorageKeys.IsSurveyCompleted,
        JSON.stringify(true)
      );
      return {
        ...state,
        ui: {
          ...state.ui,
          isSurveyCompleted: true,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state, action) => {
        // action is inferred correctly here if using TS
        return {
          ...state,
          ui: {
            ...state.ui,
            // activeScreen: SCREEN_LOADING,
          },
          loading: {
            ...state.loading,
            getSurveyQuestionsByHash: true,
          },
          errors: {
            ...state.errors,
            getSurveyQuestionsByHash: undefined,
          },
        };
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        if (action.payload[0] === "Wrong password") {
          sessionStorage.setItem(
            SessionStorageKeys.SurveyPasswordValid,
            `false`
          );
          sessionStorage.setItem(SessionStorageKeys.IsSurveyStarted, `false`);
          return {
            ...state,
            ui: {
              ...state.ui,
              isSurveyStarted: false,
              invalidPassword: true,
            },
            loading: {
              ...state.loading,
              getSurveyQuestionsByHash: false,
            },
          };
        }

        sessionStorage.setItem(SessionStorageKeys.SurveyPasswordValid, `true`);
        sessionStorage.setItem(SessionStorageKeys.IsSurveyStarted, `true`);

        let surveyAns = getArrayFromSessionStorage(
          SessionStorageKeys.SurveyAns
        );
        // sessionStorage.setItem(SessionStorageKeys.Active, SCREEN_QUESTIONS);
        const activeScreen = sessionStorage.getItem(SessionStorageKeys.Active); //getStringFromSessionStorage(SessionStorageKeys.Active);
        (async () => {
          await TrackAction({
            action: "fetchQuestions",
            other: JSON.stringify({
              apiResponse: "success",
            }),
          });
        })();
        return {
          ...state,
          data: {
            ...state.data,
            surveyPermissionsError: undefined,
            surveyExpiredError: undefined,
            completedSurvey: {
              ...state.data.completedSurvey,
              ...action.payload,
            },
            surveyAnswers:
              surveyAns && surveyAns.length
                ? surveyAns
                : action.payload?.survey_questions?.map((sq: any) => {
                    const maxIndex: number | null = _.chain(
                      sq?.domain?.answers?.[action.payload.locale] ??
                        sq?.domain?.answers?.[Locale.English]
                    )
                      .keys()
                      .max()
                      .toNumber()
                      .value();
                    return {
                      question_id: sq.id,
                      answer_index:
                        action.payload?.survey_questions_completed?.find(
                          (s: any) => s.question_id === sq.id
                        )?.answer_index,
                      max_answer_index: maxIndex == null ? 0 : maxIndex,
                    };
                  }),
          },
          ui: {
            ...state.ui,
            isSurveyStarted: true,
            invalidPassword: false,
            // if password is correct then move user to question page
            // activeScreen: activeScreen ?? SCREEN_QUESTIONS,
          },
          loading: {
            ...state.loading,
            getSurveyQuestionsByHash: false,
          },
        };
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        sessionStorage.setItem(SessionStorageKeys.SurveyPasswordValid, `false`);
        // sessionStorage.setItem(SessionStorageKeys.Active, SCREEN_PASSWORD_REQ);
        (async () => {
          await TrackAction({
            action: "fetchQuestions",
            other: JSON.stringify({
              apiResponse: "rejected",
            }),
          });
        })();

        const isPermissionError = !!(action.payload as SurveyPermissionsError)
          ?.demographic;
        return {
          ...state,
          data: {
            ...state.data,
            // completedSurvey: action.payload as any,
            surveyPermissionsError: isPermissionError
              ? (action.payload as SurveyPermissionsError)
              : undefined,
            surveyExpiredError: isPermissionError
              ? undefined
              : (action.payload as string),
          },
          loading: {
            ...state.loading,
            getSurveyQuestionsByHash: false,
          },
          ui: {
            ...state.ui,
            // activeScreen: SCREEN_PASSWORD_REQ,
            invalidPassword:
              action.error?.message == "Request failed with status code 401"
                ? true
                : state.ui.invalidPassword,
          },
        };
      })
      .addCase(fetchSurvey.pending, (state, action) => {
        // action is inferred correctly here if using TS
        return {
          ...state,
          ui: {
            ...state.ui,
            isSurveyLoaded: false,
            // activeScreen: SCREEN_LOADING,
          },
          loading: {
            ...state.loading,
            getSurveyData: true,
          },
          errors: {
            ...state.errors,
            getSurveyData: undefined,
          },
        };
      })
      .addCase(fetchSurvey.fulfilled, (state, action) => {
        // action is inferred correctly here if using TS
        if (!action.payload.session_hash) console.error("session not found!");

        let existingSession = sessionStorage.getItem(
          SessionStorageKeys.SurveySessionHash
        );

        if (!existingSession)
          sessionStorage.setItem(
            SessionStorageKeys.SurveySessionHash,
            JSON.stringify(action.payload?.session_hash)
          );

        const activeScreen = sessionStorage.getItem(SessionStorageKeys.Active);
        const isSurveyStarted = getBooleanFromSessionStorage(
          SessionStorageKeys.IsSurveyStarted
        );

        let active =
          activeScreen ??
          action.payload?.demographic !== SurveyDemographic.SchoolStaff
            ? SCREEN_LANGUAGE_SELECT
            : SCREEN_PASSWORD_REQ;
        sessionStorage.setItem(SessionStorageKeys.Active, active);
        (async () => {
          await TrackAction({
            action: "fetchSurvey",
            other: JSON.stringify({
              apiResponse: "success",
            }),
          });
        })();
        return {
          ...state,
          data: {
            ...state.data,
            surveyPermissionsError: undefined,
            surveyExpiredError: undefined,
            completedSurvey: {
              ...current(state).data.completedSurvey,
              ...action.payload,
              page_text: isSurveyStarted
                ? state.data.completedSurvey?.page_text
                : action.payload.page_text,
            },
          },
          ui: {
            ...state.ui,
            isSurveyLoaded: true,
            // check if not staff then only load this screen otherwise load password screen directly
            // activeScreen: active,
            showLanguageSelector:
              action.payload?.demographic !== SurveyDemographic.SchoolStaff
                ? true
                : false,
            navbar: {
              ...state.ui.navbar,
              languageSelectorHidden:
                action.payload?.demographic !== SurveyDemographic.SchoolStaff
                  ? true
                  : false,
            },
          },
          loading: {
            ...state.loading,
            getSurveyData: false,
          },
        };
      })
      .addCase(fetchSurvey.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        const isPermissionError = !!(action.payload as SurveyPermissionsError)
          ?.demographic;

        let active =
          action.payload?.demographic !== SurveyDemographic.SchoolStaff
            ? SCREEN_LANGUAGE_SELECT
            : SCREEN_PASSWORD_REQ;
        // sessionStorage.setItem(SessionStorageKeys.Active, active);
        (async () => {
          await TrackAction({
            action: "fetchSurvey",
            other: JSON.stringify({
              apiResponse: "rejected",
            }),
          });
        })();
        return {
          ...state,
          data: {
            ...state.data,
            completedSurvey: action.payload as any,
            surveyPermissionsError: isPermissionError
              ? (action.payload as SurveyPermissionsError)
              : undefined,
            surveyExpiredError:
              action.payload?.data["error-message"] ??
              action.payload?.data?.detail ??
              action.payload?.data,
            errorData: action.payload?.data ?? {},
          },
          ui: {
            isSurveyLoaded: true,
            ...state.ui,
            // check if not staff then only load this screen otherwise load password screen directly
            // activeScreen: active,
          },
          loading: {
            ...state.loading,
            getSurveyData: false,
          },
        };
      })
      .addCase(languageChange.pending, (state, action) => {
        // action is inferred correctly here if using TS
        return {
          ...state,
          loading: {
            ...state.loading,
            getSurveyData: true,
            getSurveyQuestionsByHash: true,
          },
          errors: {
            ...state.errors,
            getSurveyData: undefined,
          },
        };
      })
      .addCase(languageChange.fulfilled, (state, action) => {
        // action is inferred correctly here if using TS
        if (!action.payload.session_hash) console.error("session not found!");

        // save session hash in session storage  - if session already present in the session storage do not save new session
        let existingSession = sessionStorage.getItem(
          SessionStorageKeys.SurveySessionHash
        );
        if (!existingSession)
          sessionStorage.setItem(
            SessionStorageKeys.SurveySessionHash,
            JSON.stringify(action.payload?.session_hash)
          );

        return {
          ...state,
          data: {
            ...state.data,
            surveyPermissionsError: undefined,
            surveyExpiredError: undefined,
            completedSurvey: {
              ...current(state).data.completedSurvey,
              ...action.payload,
            },
            surveyAnswers: action.payload?.survey_questions?.map((sq: any) => {
              const maxIndex: number | null = _.chain(
                sq?.domain?.answers?.[action.payload.locale] ??
                  sq?.domain?.answers?.[Locale.English]
              )
                .keys()
                .max()
                .toNumber()
                .value();
              return {
                question_id: sq.id,
                answer_index: action.payload?.survey_questions_completed?.find(
                  (s: any) => s.question_id === sq.id
                )?.answer_index,
                max_answer_index: maxIndex == null ? 0 : maxIndex,
              };
            }),
          },
          loading: {
            ...state.loading,
            getSurveyData: false,
            getSurveyQuestionsByHash: false,
          },
          ui: {
            ...state.ui,
            isSurveyStarted:
              action.payload?.response?.data[0] == "Wrong password"
                ? false
                : true,
            invalidPassword:
              action.payload?.response?.data[0] == "Wrong password"
                ? true
                : false,
          },
        };
      })
      .addCase(languageChange.rejected, (state, action) => {
        // action is inferred correctly here if using TS
        const isPermissionError = !!(action.payload as SurveyPermissionsError)
          ?.demographic;

        return {
          ...state,
          data: {
            ...state.data,
            completedSurvey: action.payload as any,
            surveyPermissionsError: isPermissionError
              ? (action.payload as SurveyPermissionsError)
              : undefined,
            surveyExpiredError: isPermissionError
              ? undefined
              : (action.payload as string),
          },
          loading: {
            ...state.loading,
            getSurveyData: false,
            getSurveyQuestionsByHash: false,
          },
        };
      })
      .addCase(saveAnswer.pending, (state, action) => {
        console.log(action.meta?.arg.reqData, "< action.meta");
        // return state;
        return {
          ...state,
          data: {
            ...state.data,
            surveyAnswers: state?.data?.surveyAnswers.map((sa: any) => {
              if (
                sa.question_id ===
                action.meta?.arg?.reqData?.request?.question_id
              ) {
                return action.meta?.arg?.reqData?.request;
              }
              return sa;
            }),
          },
          loading: {
            ...state.loading,
            saveSurveyQuestionAnswer: true,
          },
          errors: {
            ...state.errors,
            saveSurveyQuestionAnswer: undefined,
          },
        };
      })
      .addCase(saveAnswer.fulfilled, (state, action) => {
        // save latest answers to session storage
        // - array of ans - session
        // - survey started by user - session

        (async () => {
          await TrackAction({
            action: "saveAnswer",
            other: JSON.stringify({
              apiResponse: "success",
              ...action.payload?.reqData,
            }),
          });
        })();

        let answers = state.data?.surveyAnswers?.map((sa) => {
          if (
            sa.question_id === action.payload?.reqData?.request?.question_id
          ) {
            return action.payload?.reqData?.request;
          }
          return sa;
        });

        sessionStorage.setItem(SessionStorageKeys.IsSurveyStarted, `true`);
        sessionStorage.setItem(
          `${SessionStorageKeys.SurveyAns}`,
          JSON.stringify(answers)
        );

        return {
          ...state,
          data: {
            ...state.data,
            surveyAnswers: answers,
          },
          loading: {
            ...state.loading,
            saveSurveyQuestionAnswer: false,
          },
        };
      })
      .addCase(saveAnswer.rejected, (state, action) => {
        // return state;
        (async () => {
          await TrackAction({
            action: "saveAnswer",
            other: JSON.stringify({
              apiResponse: "rejected",
              ...action.meta?.arg?.reqData,
            }),
          });
        })();

        return {
          ...state,
          data: {
            ...state.data,
            surveyAnswers: state.data.surveyAnswers.map((sa) => {
              if (
                sa.question_id ===
                action.meta?.arg?.reqData?.request?.question_id
              ) {
                return action.meta?.arg?.previousObject;
              }
              return sa;
            }),
          },
          errors: {
            ...state.errors,
            saveSurveyQuestionAnswer: action.payload?.error ?? true,
          },
          loading: {
            ...state.loading,
            saveSurveyQuestionAnswer: false,
          },
        };
      })
      .addCase(surveyCompleted.pending, (state, action) => {
        return {
          ...state,
          ui: {
            ...state.ui,
            // activeScreen: SCREEN_LOADING,
          },
          loading: {
            ...state.loading,
            saveCompletedSurvey: true,
          },
          errors: {
            ...state.errors,
            saveCompletedSurvey: undefined,
          },
        };
      })
      .addCase(surveyCompleted.fulfilled, (state, action) => {
        (async () => {
          await TrackAction({
            action: "surveyCompleted",
            other: JSON.stringify({
              apiResponse: "success",
            }),
          });
        })();
        // save to session
        sessionStorage.setItem(
          SessionStorageKeys.IsSurveyCompleted,
          JSON.stringify(true)
        );
        return {
          ...state,
          ui: {
            ...state.ui,
            // activeScreen: SCREEN_FINISH,
            isSurveyCompleted: true,
          },
          loading: {
            ...state.loading,
            saveCompletedSurvey: false,
          },
        };
      })
      .addCase(surveyCompleted.rejected, (state, action) => {
        // sessionStorage.setItem(SessionStorageKeys.Active, SCREEN_QUESTIONS);
        (async () => {
          await TrackAction({
            action: "surveyCompleted",
            other: JSON.stringify({
              apiResponse: "rejected",
            }),
          });
        })();
        return {
          ...state,
          ui: {
            ...state.ui,
            isSurveyCompleted: false,
            // activeScreen: SCREEN_QUESTIONS,
          },
          loading: {
            ...state.loading,
            saveCompletedSurvey: false,
          },
          errors: {
            ...state.errors,
            saveCompletedSurvey: action.payload,
          },
        };
      })
      .addDefaultCase((state, action) => {
        return { ...state };
      });
  },
});

// Actions
export const {
  updateSelectedLanguage,
  showConfirmDialog,
  hideConfirmDialog,
  resetSurveyCompletion,
  resetSurveyLanguage,
  updateSurveyStarted,
  updateInvalidPassword,
  saveTestingSurveyQuestionAnswer,
  hideLanguageSelector,
  loadExistingAns,
  updateLanguage,
  loadExistingState,
  navigateUser,
  updateSurveyCompleted,
  updateErrorPageMessage,
  userTestSurveyComplete,
} = slice.actions;

export default slice.reducer;
