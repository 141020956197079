import { SessionStorageKeys } from "../constants/session-storage";
import { getBreakPoint } from "./hooks/useWindowSize";

const trackerUrl = process.env.REACT_TRACKER_API || 'https://hrc12l6n40.execute-api.us-east-1.amazonaws.com/default/question_complete'; // ;

export const TrackAction = async (data: any) => {
  try {
    
    const sessionId = sessionStorage.getItem(
      SessionStorageKeys.SurveySessionHash
    );

    const screenSize = getBreakPoint(window.innerWidth)
    const res = await fetch(trackerUrl, {
      method: "POST",
      mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, sessionId: sessionId??'', env: process.env.REACT_TRACKER_ENV || 'staging', screenType:screenSize }),
    });
  } catch (err) {
    console.log("Tracker Error", err);
  }
};
