export const MEETING_TITLE_FIELD = "title";
export const MEETING_SHORT_TITLE_FIELD = "short_title";
export const MEETING_DATE_FIELD = "date";
export const MEETING_END_DATE_FIELD = "end_date";
export const MEETING_GENERAL_NOTES_FIELD = "general_notes";
export const MEETING_PRE_WORK_FIELD = "pre_work";
export const MEETING_AGENDA_FIELD = "agenda";
export const MEETING_ATTACHED_RESOURCES_FIELD = "attached_resources";
export const MEETING_ACTIVITIES_FIELD = "activities";
export const MEETING_TIMING_GUIDANCE = "timing_guidance";
export const TEAM_PLAN_NAVIGATION_WIDTH = "17rem";
export const TEAM_PLAN_PAGES_PADDING = "1.25rem";

export const NOTE = "note";

export const DOMAIN_DEFAULT_COLOR = "#1A2654";
export const DOMAIN_DEFAULT_COLOR_DISABLED = "#c0c0c0";
export const DOMAIN_TEXT_DEFAULT_COLOR_DISABLED = "#ffffff";

export const DEFAULT_PRECISION = 2;

export const INDICATOR_DOMAIN_LEVEL = 'domain_level';
export const INDICATOR_ITEM_LEVEL = 'item_level';

export const NEW_GOAL_MODE = 'new';
export const EDIT_GOAL_MODE = 'edit';

export const GOAL_CREATED_SUCCESSFULLY = 'Goal created successfully';
export const GOAL_UPDATED_SUCCESSFULLY = 'Goal updated successfully';
export const GOAL_INDICATOR_UPDATED_SUCCESSFULLY = 'Goal indicator updated successfully';
export const NEW_GOAL_ADDED = 'A new goal is added'


export const SURVEYS_TABLE="surveys-table";
export const SURVEYS_DEPLOYMENT_TABLE="surveys-deployment-table";

export const DESIRED_OUTCOME_CHARACTER_LIMIT=1500;

export const SCREEN_LOADING = 'SCREEN_LOADING'; 
export const SCREEN_LANGUAGE_SELECT = 'SCREEN_LANGUAGE_SELECT'; 
export const SCREEN_PASSWORD_REQ = 'SCREEN_PASSWORD_REQ'; 
export const SCREEN_QUESTIONS = 'SCREEN_QUESTIONS'; 
export const SCREEN_FINISH = 'SCREEN_FINISH'; 
export const SCREEN_ERR = 'SCREEN_ERR'; 