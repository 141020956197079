import React from "react";
import Sticky from "react-sticky-el";

type OwnProps = {
  translatedLeadIn: string;
};

type Props = OwnProps;

const LeadInSticky: React.FC<Props> = ({ translatedLeadIn }: Props) => {
  return (
    <Sticky
      stickyClassName="bg-gray-600 shadow-md text-white opacity-90 rounded-md"
      stickyStyle={{ zIndex: 100 }}
    >
      <div className="text-xl sm:text-2xl ml-4 my-4 font-bold">
        {translatedLeadIn}
      </div>
    </Sticky>
  );
};

export default LeadInSticky;
