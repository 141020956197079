import React, { FunctionComponent } from "react";
import { Icon } from "@blueprintjs/core";

const ICON_SIZE = 26;

const CustomArrow: FunctionComponent<any> = props => {
  return (
    <Icon
      iconSize={ICON_SIZE}
      icon={props.isPrev ? "chevron-left" : "chevron-right"}
      className="text-black hover:text-blue-600 cursor-pointer absolute border-none"
      style={{ [props.isPrev ? "left" : "right"]: "-25px", bottom: "50%" }}
      onClick={props.onClick}
    />
  );
};

export default CustomArrow;
