import { Navbar } from '@blueprintjs/core'
import React from 'react'
import { LanguageSelector } from './LanguageSelector';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

const NAVBAR_HEIGHT = 50;
const TEST_MODE_BORDER_WIDTH = 14;

export const Header = ({isTesting}:{isTesting:boolean}) => {
  const intl = useIntl();
  const showLanguageSelector = useSelector(
    (s:any) => s.survey.ui?.showLanguageSelector
  );

  return (
    <Navbar
    style={{
      marginTop: isTesting ? NAVBAR_HEIGHT - TEST_MODE_BORDER_WIDTH : 0,
    }}
  >
    <Navbar.Group align="left">
      <Navbar.Heading className="font-bold">
        {intl.formatMessage({ id: "app.title" })}
      </Navbar.Heading>
      {showLanguageSelector && <LanguageSelector renderOnNavbar={true}/>}
    </Navbar.Group>
    
  </Navbar>
  )
}
