import React, { useMemo } from "react";
import { Button } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { CompletedDomain, Locale, SurveyQuestion } from "../types";

type AnswerOption = {
  key: number;
  value: string;
};

type OwnProps = {
  surveyQuestion: SurveyQuestion<number, CompletedDomain>;
  answerOptions: AnswerOption[];
  isAnswerActive: (
    surveyQuestion: SurveyQuestion<number, CompletedDomain>,
    index: number
  ) => boolean;
  onQuestionAnswerClick: (
    index: number,
    surveyQuestion: SurveyQuestion<number, CompletedDomain>
  ) => void;
  isNotAnswered?: boolean;
  direction: string;
  hash?:string;
  school?:string;
  locale:any;
};

type Props = OwnProps;

const SurveyQuestionCard: React.FC<Props> = (props: Props) => {
  const {
    surveyQuestion,
    answerOptions,
    isAnswerActive,
    onQuestionAnswerClick,
    isNotAnswered,
    direction,
    hash,
    school,
    locale
  } = props;

  // const locale = useSelector((s:any) => s.survey.ui.selectedLanguage);
  const orderedQuestionText = useMemo(() => {
    // debugger
    const questionText = surveyQuestion.translations
      ? typeof surveyQuestion.translations[locale]?.text === "string" //fix app crash with old data (sometimes get object from server)
        ? surveyQuestion.translations[locale]?.text
        : surveyQuestion.text
      : surveyQuestion.text;

    const questionCleanText = questionText?.replaceAll(/\s/g, " ");

    // return `${surveyQuestion.order + 1}. ${questionCleanText}`;
    return `${questionCleanText}`;
  }, [surveyQuestion, locale]);


  return (
    <div
      className={"survey-question-card"}
      style={{
        background: isNotAnswered ? "rgba(217, 130, 43, 0.5)" : "",
      }}
      dir={direction}
    >
      <div
        style={{
          textAlign: direction === "rtl" ? "right" : "left",
        }}
      >
        {orderedQuestionText}
      </div>
      <div
        className={
          surveyQuestion.is_not_applicable_answer
            ? "not-applicable-answer"
            : "usual-answer"
        }

      >
       
        
        {answerOptions.map((option, index) => {
          const isActive = isAnswerActive(surveyQuestion, option.key);
         
          return (
            <div key={`${surveyQuestion.order}_${index}`} >
              <Button
                key={index}
                onClick={() =>{
                  // debugger;
                  onQuestionAnswerClick(option.key, surveyQuestion)
                }
                }
                intent={isActive ? "primary" : undefined}
                className={`w-full text-center rounded-none text-xl button-min-height ${
                  isActive ? "font-bold active" : ""
                }`}
                title={option.value}
                text={option.value?.replaceAll(/\s/g, " ")}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SurveyQuestionCard;
