import { UserModel } from "../types";
import moment from "moment";
import { Dataset } from "../types";

const createErrorMessage = (obj: any): string => {
  if (typeof obj === "string" || obj instanceof String) {
    return obj as string;
  }

  if (Array.isArray(obj)) {
    return obj.map(createErrorMessage).join("\n");
  }

  return Object.entries(obj).reduce((resMessage, [key, value]) => {
    let nextMessage = "";
    if (Array.isArray(value)) {
      nextMessage += value.map(createErrorMessage).join(", ");
    } else if (typeof value === "string" || value instanceof String) {
      nextMessage += value;
    } else {
      nextMessage += createErrorMessage(value);
    }

    return resMessage + nextMessage;
  }, "");
};

export const getErrorMessage = (err: any) => {
  if (err.response && err.response.message) {
    if (err.response.errors) {
      return {
        title: err.response.message,
        message: createErrorMessage(err.response.errors),
      };
    } else {
      return {
        title: err.response.message,
      };
    }
  } else if (err.isAxiosError) {
    let messageObj;

    try {
      messageObj = JSON.parse(err.request.response);
    } catch (e) {
      // when we reserve 400s/500s errors
      messageObj = err.request.responseURL;
    }

    return {
      title: "Error",
      message: createErrorMessage(messageObj),
    };
  } else {
    return {
      title: "Error",
      message: err.message,
    };
  }
};

export const generateSurveyDeploymentName = (
  surveyName: string,
  endDate: string
) => {
  let name = surveyName;

  const date = moment(endDate);
  const month = date.month();
  const year = date.year();
  let season: string = "";

  if (month >= 2 && month <= 5) {
    season = "Spring";
  } else if (month >= 6 && month <= 7) {
    season = "Summer";
  } else if (month >= 8 && month <= 10) {
    season = "Fall";
  } else {
    season = "Winter";
  }

  if (!name.includes(season)) {
    name += ` ${season}`;
  }

  if (!name.includes(year.toString())) {
    name += ` ${year}`;
  }

  return name;
};

export const generateDatasetName = (dataset: Dataset) => {
  let title;
  if (dataset?.domain) {
    if (dataset.question) {
      if (dataset.question.lead_in) {
        title = `${dataset.domain.name} (${dataset.question.lead_in} ${dataset.question.text})`;
      } else {
        title = `${dataset?.domain?.name} (${dataset?.question?.text})`;
      }
    } else {
      title = `${dataset?.domain?.name}`;
    }
  } else {
    title = dataset?dataset.name:'';
  }
  return title;
};

export const getFullName = <
  T extends Pick<UserModel, "first_name" | "last_name">
>(
  user: T
) => {
  return `${user.first_name} ${user.last_name}`;
};

const SURVEY_MONTH: number = 6;
let SURVEY_NEXT_YEAR: string = "";
let SURVEY_PREVIOUS_YEAR: string = "";
let SURVEY_ADMIN: number = 1;
let SURVEY_NAME: string = "";

export const generateSurveyDeploymentAdmin = (
  date: string,
  name: string,
  reset?: boolean
) => {
  if (SURVEY_NAME !== name || reset) {
    SURVEY_NAME = name;
    SURVEY_NEXT_YEAR = "";
    SURVEY_PREVIOUS_YEAR = "";
    SURVEY_ADMIN = 1;
  }
  if(reset)
    return '';
    
  if (parseInt(moment(date).format("M")) > SURVEY_MONTH) {
    if (
      moment(date).add(1, "years").format("YYYY") === SURVEY_NEXT_YEAR &&
      SURVEY_PREVIOUS_YEAR === moment(date).format("YYYY")
    ) {
      SURVEY_ADMIN = 1 + SURVEY_ADMIN;
      return `${SURVEY_NAME} ${moment(date).format("YYYY")}-${moment(date)
        .add(1, "years")
        .format("YYYY")} Administration #${SURVEY_ADMIN}`;
    } else {
      SURVEY_PREVIOUS_YEAR = moment(date).format("YYYY");
      SURVEY_ADMIN = 1;
      SURVEY_NEXT_YEAR = moment(date).add(1, "years").format("YYYY");
      return `${SURVEY_NAME} ${moment(date).format("YYYY")}-${moment(date)
        .add(1, "years")
        .format("YYYY")} Administration #${SURVEY_ADMIN}`;
    }
  } else {
    if (
      moment(date).format("YYYY") === SURVEY_NEXT_YEAR &&
      SURVEY_PREVIOUS_YEAR === moment(date).subtract(1, "year").format("YYYY") 
    ) {
      SURVEY_ADMIN = 1 + SURVEY_ADMIN;
      return `${SURVEY_NAME} ${moment(date)
        .subtract(1, "year")
        .format("YYYY")}-${moment(date).format(
        "YYYY"
      )} Administration #${SURVEY_ADMIN}`;
    } else {
      SURVEY_NEXT_YEAR = moment(date).format("YYYY");
      SURVEY_ADMIN = 1;
      SURVEY_PREVIOUS_YEAR = moment(date).subtract(1, "year").format("YYYY");
      return `${SURVEY_NAME} ${moment(date)
        .subtract(1, "year")
        .format("YYYY")}-${moment(date).format(
        "YYYY"
      )} Administration #${SURVEY_ADMIN}`;
    }
  }

};
